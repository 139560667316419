import React, { Component } from 'react';
import { Mutation } from '@apollo/client/react/components';
import { Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

import SignInForm from './SignInForm';
import { SignInMutation } from '../mutations/SignIn';

import { signIn } from '../auth';

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  error: {
    backgroundColor: red[300]
  }
});

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signInError: ''
    };
  }

  _confirm = async (data) => {
    // TODO: get this error messaging working
    if (!data.signIn) {
      this.setState({
        signInError:
          'There was a problem signing you in. Check your credentials.'
      });
      return;
    }
    signIn(data);
    window.location = this.props.from;
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root} square>
        <Mutation
          mutation={SignInMutation}
          onCompleted={(data) => this._confirm(data)}
        >
          {(signIn, { data, error }) => {
            if (error) return <p>Error.</p>;

            return <SignInForm signIn={signIn} />;
          }}
        </Mutation>
      </Paper>
    );
  }
}

export default withStyles(styles)(SignIn);

import React, { Component } from 'react'
import Moment from 'react-moment';
import 'moment-timezone';
import { Typography } from '@material-ui/core'

class LastEvent extends Component {
  render() {
    const { event, align } = this.props
    if(event) {
      return (
        <span>
          <Typography variant="body1" style={{ fontSize: 17, fontWeight: 500, }}>
            { event.eventType }
          </Typography>
          <Typography variant="body2" style={{ color: "#7A7A7A", fontSize: 15, fontWeight: 500 }}>
            <Moment format="MMM DD">{ event.timestamp }</Moment> • <Moment fromNow>{ event.timestamp }</Moment>
          </Typography>
        </span>
      )
    }
    return(<span></span>)
  }
}
LastEvent.defaultProps = {
  align: "center"
}

export default LastEvent

import { gql } from '@apollo/client';

export const UnassignMutation = gql`
  mutation UnassignMutation($id: ID!) {
    unassignBoard(id: $id) {
      ok
      board {
        id
        isAssignedToMe
      }
    }
  }
`;

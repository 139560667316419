import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import {
  Button,
  Tabs,
  Tab,
  Card,
  CardContent,
  capitalize,
  Grid,
  Tooltip,
  Typography,
  Link,
  Chip,
  useTheme
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import BidListing from './BidListing';
import { EventListing } from './EventListing';
import { NoteListing } from './NoteListing';
import { MessagesListing } from './MessagesListing';
import { RouteListing } from './RouteListing';
import { isExternal } from '../auth';
import {
  GetGigExtendedDetails,
  GetEventsForGig,
  GetNotesForGig,
  GetMessagesForGig,
  GetBidsForGig
} from '../queries/getGig';
import { POLLING_INTERVALS } from '../constants';
import Loader from './Loader';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: "100%",
      boxShadow: "none",
      border: "1px solid #EAEAEA" 
    },
    details: {
      padding: "0px 25px 9px 25px"
    },
    stateChip: {
      backgroundColor: "#003838",
      color: "white",
      float: "right",
      fontSize: 15,
      fontWeight: 500,
      padding: "0",
      height: 18,
      borderRadius: 4,
      textTransform: 'uppercase'
    },
    driverText: {
      fontSize: 17,
      fontWeight: 400,
      letterSpacing: .25
    },
    link: {
      color: "#008383"
    },
    name: {
      fontSize: 17,
      fontWeight: 400,
      letterSpacing: .25,
      color: "#008383" 
    },
    nameSubText: {
      fontSize: 15,
      fontWeight: 500,
      paddingTop: 0,
      paddingLeft: 0,
      color: "black"
    },
    subtitle2: {
      fontSize: 15,
      fontWeight: 500,
      letterSpacing: 2,
      color: "#7A7A7A"
    }
  })
);

const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}

const PhoneText = ({driver}) => {
  const [copied, setCopied] = useState(false);

  if (driver.phoneNumber) {
    
    const driverPhone = formatPhoneNumber(driver.phoneNumber)
    return (
      <Typography variant="body2" component="span">
        <Tooltip title={ copied ? 'Copied phone number!' : 'Copy Driver Phone Number'}>
          <CopyToClipboard text={ driverPhone } onCopy={() => setCopied(true) }>
            <Button
              color="primary"
              size='small'
              style={{
                fontSize: 15,
                fontWeight: 500,
                paddingTop: 0,
                paddingLeft: 0,
                color: "black" 
              }}
              onClick={ (event) => event.stopPropagation()}
            >
              { driverPhone } &#160;
              <FileCopyIcon fontSize='inherit' style={{ color: "#008383"}}/>
            </Button>
          </CopyToClipboard>
        </Tooltip>
      </Typography>
    )
  }
}

    
export const GigExtendedDetailsTabs = ({ gigId, showFirstAndLastName, textlineGroupUuid }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [tab, setTab] = useState('events');
  const currentPollWorker = useRef(null);

  const {
    data: gigExtendedDetails,
    error: gigExtendedDetailsError,
    loading: gigExtendedDetailsLoading
  } = useQuery(GetGigExtendedDetails, {
    variables: { id: gigId },
    pollInterval: POLLING_INTERVALS.GIG,
    nextFetchPolicy: 'network-only'
  });

  const [
    loadEvents,
    {
      data: eventsData,
      error: eventsError,
      loading: eventsLoading,
      called: eventsCalled,
      startPolling: startPollingEvents,
      stopPolling: stopPollingEvents
    }
  ] = useLazyQuery(GetEventsForGig, {
    variables: { id: gigId },
    nextFetchPolicy: 'network-only'
  });

  const [
    loadNotes,
    {
      data: notesData,
      error: notesError,
      loading: notesLoading,
      called: notesCalled,
      startPolling: startPollingNotes,
      stopPolling: stopPollingNotes
    }
  ] = useLazyQuery(GetNotesForGig, {
    variables: { id: gigId },
    nextFetchPolicy: 'network-only'
  });

  const [
    loadMessages,
    {
      data: messagesData,
      error: messagesError,
      loading: messagesLoading,
      called: messagesCalled,
      startPolling: startPollingMessages,
      stopPolling: stopPollingMessages
    }
  ] = useLazyQuery(GetMessagesForGig, {
    variables: { id: gigId },
    nextFetchPolicy: 'network-only'
  });

  const [
    loadOffers,
    {
      data: offersData,
      error: offersError,
      loading: offersLoading,
      called: offersCalled,
      startPolling: startPollingOffers,
      stopPolling: stopPollingOffers
    }
  ] = useLazyQuery(GetBidsForGig, {
    variables: { id: gigId },
    nextFetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (!eventsCalled) {
      loadEvents();
      startPollingEvents(POLLING_INTERVALS.GIG);
      currentPollWorker.current = {
        stop: stopPollingEvents,
        start: startPollingEvents
      };
    }
  });

  const handlePollWorkerChange = useCallback(
    ({ start, stop, load, hasBeenCalled }) => {
      if (currentPollWorker.current) {
        currentPollWorker.current.stop();
      }
      if (!hasBeenCalled) {
        load();
      }
      start(POLLING_INTERVALS.GIG);
      currentPollWorker.current = { stop, start };
    },
    [currentPollWorker]
  );

  const handleTabChange = useCallback(
    (_, tabName) => {
      setTab(tabName);
      switch (tabName) {
        case 'events':
          handlePollWorkerChange({
            start: startPollingEvents,
            stop: stopPollingEvents,
            load: loadEvents,
            hasBeenCalled: eventsCalled
          });
          break;
        case 'notes':
          handlePollWorkerChange({
            start: startPollingNotes,
            stop: stopPollingNotes,
            load: loadNotes,
            hasBeenCalled: notesCalled
          });
          break;
        case 'messages':
          handlePollWorkerChange({
            start: startPollingMessages,
            stop: stopPollingMessages,
            load: loadMessages,
            hasBeenCalled: messagesCalled
          });
          break;
        case 'offers':
          handlePollWorkerChange({
            start: startPollingOffers,
            stop: stopPollingOffers,
            load: loadOffers,
            hasBeenCalled: offersCalled
          });
          break;
        default:
          break;
      }
    },
    [
      eventsCalled,
      handlePollWorkerChange,
      loadEvents,
      loadMessages,
      loadNotes,
      loadOffers,
      messagesCalled,
      notesCalled,
      offersCalled,
      startPollingEvents,
      startPollingMessages,
      startPollingNotes,
      startPollingOffers,
      stopPollingEvents,
      stopPollingMessages,
      stopPollingNotes,
      stopPollingOffers
    ]
  );

  useEffect(() => {
    if (!eventsCalled) {
      loadEvents();
      startPollingEvents(POLLING_INTERVALS.GIG);
      currentPollWorker.current = {
        stop: stopPollingEvents,
        start: startPollingEvents
      };
    }
  });

  const getDriverText = (assignedConsolidationsCount, assignedGigsCount) => {
    const batchCount = assignedConsolidationsCount;
    const gigCount = assignedGigsCount;
    return `${batchCount} Batches, ${gigCount} Deliveries`;
  };

  const gridLabel = (label) => {
    return (
      <Typography
        variant="subtitle2"
        className={classes.subtitle2}
      >
        { label }
      </Typography>
    )
  }

  if (gigExtendedDetailsLoading) {
    return <div>{<Loader />}</div>;
  }

  if (gigExtendedDetailsError) {
    return <div>Error: {gigExtendedDetailsError.message}</div>;
  }

  if (gigExtendedDetails && gigExtendedDetails?.gig) {
    const {
      id,
      events: { totalCount: eventsTotalCount = 0 } = { totalCount: 0 },
      bids: { totalCount: offersTotalCount = 0 } = { totalCount: 0 },
      notes: { totalCount: notesTotalCount = 0 } = { totalCount: 0 },
      referenceId = null,
      recipient: { name: recipientName } = { name: null },
      consolidationDeliveryCount = null,
      state = null
    } = gigExtendedDetails?.gig;

    const driver =
      (gigExtendedDetails?.gig?.driver ?? null)
        ? {
            ...{
              name: null,
              id: null,
              assignedConsolidationsCount: 0,
              assignedGigsCount: 0
            },
            ...gigExtendedDetails?.gig?.driver
          }
        : null;

    const sender =
      (gigExtendedDetails?.gig?.sender ?? null)
        ? {
            ...{
              name: null,
              firstAndLastName: null,
              masterAccount: { name: null }
            },
            ...gigExtendedDetails?.gig?.sender
          }
        : null;

    const route =
      (gigExtendedDetails?.gig?.consolidation ?? null)
        ? {
            ...{ notes: { totalCount: 0 } },
            ...gigExtendedDetails?.gig?.consolidation
          }
        : {
            notes: { totalCount: 0 }
          };

    return (
      <Card className={classes.root}>
        <CardContent>
        {state &&
          <Chip className={classes.stateChip} label={ capitalize(state) }/>}
          <Grid
            container
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="top"
            className={classes.details} 
          >
            <Grid item md={4}>
            { gridLabel("Driver") }
              {driver && (
                <div>
                  <Typography className={classes.driverText} variant="body1">
                    <Link
                      className={classes.link} 
                      target="_blank"
                      href={`https://admin.roadie.com/profiles/${driver?.id ? driver.id : ""}`}
                    >
                      {driver?.name}
                    </Link>
                  </Typography>
                  <PhoneText driver={driver}></PhoneText>
                </div>
              )}
            </Grid>
            <Grid item md={4}>
              { gridLabel("Sender") }
              {sender && (
                <div>
                  <Typography variant="body1" className={classes.name}>
                    <Link
                      className={classes.link} 
                      target="_blank"
                      href={`https://admin.roadie.com/profiles/${sender?.id ? sender.id : ""}`}
                    >
                      {showFirstAndLastName && sender?.senderFirstAndLastName
                        ? sender?.senderFirstAndLastName
                        : sender?.name}
                    </Link>
                  </Typography>
                  {sender?.masterAccount?.name && (
                    <Typography variant="body2" className={classes.nameSubText}>
                      {sender?.masterAccount?.name}
                    </Typography>
                    )}
                </div>
              )}
            </Grid>
            <Grid
              item
              md={4}
              container
              direction="column"
            >
              { gridLabel("Recipient") }
              {recipientName && (
                <Typography 
                  variant="body2" 
                  className={classes.name}
                >
                  { recipientName }
                </Typography>
              )}

              {(referenceId) && (
                <Typography
                  variant="body2"
                  className={classes.nameSubText}
                >
                  Ref: { referenceId || "N/A" }
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={2}
            justifyContent="space-between"
          >
            <Tabs
              value={tab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
            >
              <Tab label={`Events (${eventsTotalCount})`} value="events" />
              {consolidationDeliveryCount > 1 && route && (
                <Tab label="Batch" value="route" />
              )}
              <Tab label={`Offers (${offersTotalCount})`} value="offers" />
              {!isExternal() && (
                <Tab
                  label={`Notes (${notesTotalCount + route?.notes?.totalCount})`}
                  value="notes"
                />
              )}
              {!isExternal() && driver && (
                <Tab label="Messages" value="messages" />
              )}
            </Tabs>
            <Grid item xs={12}>
              {tab === 'events' && (
                <EventListing
                  loading={eventsLoading}
                  data={eventsData}
                  error={eventsError}
                />
              )}
              {tab === 'route' && <RouteListing route={route} gigId={gigId} />}
              {tab === 'offers' && (
                <BidListing
                  loading={offersLoading}
                  data={offersData}
                  error={offersError}
                  gigId={gigId}
                />
              )}
              {tab === 'notes' && (
                <NoteListing
                  loading={notesLoading}
                  data={notesData}
                  error={notesError}
                />
              )}
              {tab === 'messages' && (
                <MessagesListing
                  gig={gigExtendedDetails?.gig}
                  loading={messagesLoading}
                  data={messagesData}
                  messagesError={messagesError}
                  textlineGroupUuid={ textlineGroupUuid }
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
};

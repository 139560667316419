import { gql } from '@apollo/client';

export const MyBoardsHome = gql`
  query MyBoardsHome {
    id
    allBucketCounts {
      id
      critical
      high
      medium
      low
      minimal
    }
    gigCountsByRule {
      id
      critical {
        rule
        count
      }
      high {
        rule
        count
      }
      medium {
        rule
        count
      }
      low {
        rule
        count
      }
      minimal {
        rule
        count
      }
    }
    myBoards {
      id
      name
      priority
      boardColor
      counts {
        id
        title
        priority
        gigCount
      }
    }
  }
`;

export const MyBoardsWithBucketsQuery = gql`
  query MyBoardsWithBucketsQuery($boardId: ID!, $filter: FilterInput) {
    board(id: $boardId) {
      id
      ...BoardFragment
      buckets {
        id
        ...BucketWithFilteredGigsCountFragment
      }
    }
  }
`;

export const MyBoardsWithBucketsByPriorityAndRule = gql`
  query MyBoardsWithPriorityBuckets(
    $priority: String!
    $title: String
    $filter: FilterInput
  ) {
    myBoards {
      id
      ...BoardFragment
      bucketsByPriority(priority: $priority, title: $title) {
        id
        ...BucketWithFilteredGigsCountFragment
      }
    }
  }
`;

export const GigsByBucket = gql`
  query GigsByBucket(
    $order: String
    $boardId: ID!
    $pageNumber: Int!
    $recordsPerPage: Int!
    $bucketId: String!
    $filter: FilterInput
  ) {
    myBoards(id: $boardId) {
      id
      showFirstAndLastName
      textlineGroupUuid
      buckets(bucketId: $bucketId) {
        id
        ...BucketFragment
        gigs(
          order: $order
          pageNumber: $pageNumber
          recordsPerPage: $recordsPerPage
          filter: $filter
        ) {
          id
          ...GigBasicDetailsFragment
          deliveryLocation {
            id
            timezone
          }
          driver {
            id
            ...DriverBasicDetailsFragment
          }
          consolidation {
            id
            ...GigConsolidationDetailsFragment
          }
        }
      }
    }
  }
`;

export const GigsByPriorityBucket = gql`
  query GigsByPriorityBucket(
    $priority: String!
    $order: String
    $title: String
    $boardId: ID!
    $bucketId: ID!
    $pageNumber: Int!
    $recordsPerPage: Int!
    $filter: FilterInput
  ) {
    myBoards(id: $boardId) {
      id
      showFirstAndLastName
      textlineGroupUuid
      bucketsByPriority(
        priority: $priority
        title: $title
        bucketId: $bucketId
      ) {
        id
        ...BucketFragment
        gigs(
          order: $order
          pageNumber: $pageNumber
          recordsPerPage: $recordsPerPage
          filter: $filter
        ) {
          id
          ...GigBasicDetailsFragment
          deliveryLocation {
            id
            timezone
          }
          driver {
            id
            ...DriverBasicDetailsFragment
          }
          consolidation {
            id
            ...GigConsolidationDetailsFragment
          }
        }
      }
    }
  }
`;

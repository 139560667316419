import React, { useCallback, useEffect, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { useQuery, makeVar } from '@apollo/client';
import { Grid, Typography, Hidden, useTheme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { GigTable } from './GigTable';
import { GigsByPriorityBucket, GigsByBucket } from '../queries/myBoards';
import Loader from './Loader';
import { getBucketFromQueryResult } from '../Helper';
import {
  ORDER_BY,
  GIGS_BY_BUCKET_PAGINATION_LIMIT,
  POLLING_INTERVALS
} from '../constants';
import { filter, get } from 'lodash';

const styles = (theme) => ({
  red: {
    backgroundColor: '#EF9A9A'
  },
  orange: {
    backgroundColor: '#FFCC80'
  },
  yellow: {
    backgroundColor: '#FFF59D'
  },
  green: {
    backgroundColor: '#A5D6A7'
  },
  gray: {
    backgroundColor: 'lightgray'
  },
  column: {
    flexBasis: '25%'
  },
  gigRow: {
    flex: '0 0 100%'
  },
  panelDetails: {
    padding: 0,
    flexDirection: 'column'
  },
  title: {
    fontSize: 26,
    opacity: 0.8
  },
  section: {
    marginBottom: 15
  },
  popover: {
    padding: theme.spacing(1),
    pointerEvents: 'none'
  },
  infoIcon: {
    width: 20,
    paddingLeft: 8
  },
  titleWithIcon: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    alignItems: 'center'
  },
  titlePadding: {
    maxHeight: 60,
    minHeight: 60,
    paddingLeft: 32,
    paddingRight: 72
  }
});

const filterColorMap = {
  find_driver: 'red',
  contact_driver: 'orange',
  monitor: 'yellow',
  dont_worry: 'green',

  critical: 'red',
  high: 'orange',
  medium: 'yellow',
  low: 'green',
  minimal: 'gray'
};

const useStyles = makeStyles((theme) => createStyles(styles(theme)));

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

const ColumnHeader = ({ text, xs, md, align = 'center' }) => (
  <Grid item {...{ xs, md }}>
    <Typography variant="subtitle2" align={align}>
      {text}
    </Typography>
  </Grid>
);

export const loadedGigCountForBucket = makeVar(0);

export const Bucket = ({
  boardId,
  bucketId,
  priority,
  rule,
  gigCount,
  showFirstAndLastName,
  isStandardBoard = true,
  headerColor,
  appliedFilters
}) => {
  const query = isStandardBoard ? GigsByBucket : GigsByPriorityBucket;
  const vars = useMemo(() => {
    const baseVars = {
      boardId,
      bucketId,
      filter: appliedFilters
    };
    return isStandardBoard
      ? baseVars
      : { ...baseVars, priority, title: rule ? rule : null };
  }, [boardId, bucketId, appliedFilters, priority, rule, isStandardBoard]);

  const totalPages = Math.ceil(gigCount / GIGS_BY_BUCKET_PAGINATION_LIMIT);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const { data, loading, error, fetchMore } = useQuery(query, {
    variables: {
      ...vars,
      pageNumber: currentPageNumber,
      recordsPerPage: GIGS_BY_BUCKET_PAGINATION_LIMIT,
      order: ORDER_BY[1].value
    },
    pollInterval: POLLING_INTERVALS.BUCKET_EXPANDED,
    nextFetchPolicy: 'network-only'
  });

  const theme = useTheme();
  const classes = useStyles(theme);
  const headerColorClass = headerColor
    ? classes[headerColor]
    : classes[filterColorMap[priority]];

  const getNewPageOfGigs = useCallback(
    (pageNumber) => {
      fetchMore({
        fetchPolicy: 'network-only',
        variables: {
          ...vars,
          pageNumber,
          recordsPerPage: GIGS_BY_BUCKET_PAGINATION_LIMIT,
          order: ORDER_BY[1].value
        }
      });
    },
    [fetchMore, vars]
  );

  useEffect(() => {
    setCurrentPageNumber(1);
    getNewPageOfGigs(1);
  }, [appliedFilters, getNewPageOfGigs]);

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }
  if (data) {
    const fetchedBucket = getBucketFromQueryResult(
      data,
      boardId,
      bucketId,
      isStandardBoard ? 'buckets' : 'bucketsByPriority'
    );
    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
          className={`${classes.titlePadding} ${headerColorClass}`}
        >
          <ColumnHeader md={3} xs={8} text="Title" align="left" />
          <Hidden smDown>
            <ColumnHeader md={2} text="Value" align="left" />
            <ColumnHeader md={2} text="Distance" align="left" />
            <ColumnHeader md={3} text="Last Event" align="left" />
            <ColumnHeader md={3} text="Driver" align="left" />
            <ColumnHeader md={2} text="Target" align="left" />
          </Hidden>
          <ColumnHeader md={2} xs={4} text="Deadline" align="left" />
        </Grid>
        <GigTable
          classes={classes}
          key={bucketId}
          gigs={fetchedBucket?.gigs}
          boardId={boardId}
          showFirstAndLastName={data?.myBoards[0]?.showFirstAndLastName}
          textlineGroupUuid={data?.myBoards[0]?.textlineGroupUuid}
          bucketId={bucketId}
        >
          {totalPages && (
            <PaginationContainer>
              <Pagination
                count={totalPages}
                onChange={(_, page) => {
                  getNewPageOfGigs(page);
                  setCurrentPageNumber(page);
                }}
                page={currentPageNumber}
                size="small"
                color="primary"
                variant="outlined"
              />
            </PaginationContainer>
          )}
        </GigTable>
      </>
    );
  }
};

import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Link,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Hidden
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import HomeIcon from '@material-ui/icons/Home';
import UsersIcon from '@material-ui/icons/SupervisorAccount';
import GroupsIcon from '@material-ui/icons/SupervisedUserCircle';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import { AUTH_TOKEN } from '../constants';
import { currentUser, hasRoles } from '../auth';
import BoardDialog from './BoardDialog';
import { SignOutButton } from './SignOutButton';

const styles = (theme) => ({
  root: {
    flexGrow: 1
  },
  flex: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -5,
  },
  noShadow: {
    boxShadow: "none"
  },
  list: {
    width: 250
  },
  appBar: {
    flexGrow: 1,
    maxHeight: 60
  },
  headerLink: {
    color: '#FFF'
  },
  title: {
    flexGrow: 1
  },
  userIcon: {
    verticalAlign: 'top'
  },
  email: {
    paddingLeft: 5
  }
});

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      createFormOpen: false
    };
  }

  toggleDrawer = (open) => () => {
    this.setState({
      menuOpen: open
    });
  };

  _handleCreateBoard = () => {
    this.setState({ createFormOpen: true });
  };

  _handleFormClose = () => {
    this.setState({ createFormOpen: false });
  };

  render() {
    const { classes } = this.props;
    const { menuOpen } = this.state;
    const authToken = localStorage.getItem(AUTH_TOKEN);

    let menuButton;
    let userButton;
    if (authToken) {
      menuButton = (
        <IconButton
          className={classes.menuButton}
          color="inherit"
          aria-label="Menu"
          onClick={this.toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
      );
      userButton = (
        <IconButton
          color="inherit"
          aria-label={`${currentUser().email} settings`}
          component="a"
          href="/me"
        >
          <AccountCircleIcon />
          <Hidden smDown>
            <Typography
              noWrap
              variant="body2"
              align="right"
              className={classes.email}
            >
              {currentUser().email}
            </Typography>
          </Hidden>
        </IconButton>
      );
    }
    let adminOptions;
    if (hasRoles(['admin', 'dashboard_admin'])) {
      adminOptions = (
        <span>
          <Divider />
          <ListItem button component="a" href="/users">
            <ListItemIcon>
              <UsersIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
          <ListItem button component="a" href="/groups">
            <ListItemIcon>
              <GroupsIcon />
            </ListItemIcon>
            <ListItemText primary="Groups" />
          </ListItem>
        </span>
      );
    }

    let managerOptions;
    if (hasRoles(['admin', 'dashboard_admin', 'dashboard_manager'])) {
      managerOptions = (
        <div>
          <ListItem button onClick={this._handleCreateBoard}>
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary="Create New Board" />
          </ListItem>
          <ListItem button component="a" href="/boards">
            <ListItemIcon>
              <ViewQuiltIcon />
            </ListItemIcon>
            <ListItemText primary="All Boards" />
          </ListItem>
        </div>
      );
    }

    return (
      <div className={classes.appBar}>
        <AppBar position="static" className={classes.noShadow}>
          <Toolbar>
            {menuButton}
            <Typography
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              <Link
                component={RouterLink}
                to="/"
                className={classes.headerLink}
              >
                Roadie Dashboard
              </Link>
            </Typography>
            {userButton}
          </Toolbar>
        </AppBar>
        <Drawer open={menuOpen} onClose={this.toggleDrawer(false)}>
          <div tabIndex={0} role="button">
            <div className={classes.list}>
              <List>
                <ListItem button component="a" href="/">
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItem>
                {managerOptions}
                {adminOptions}
                <Divider />
                <SignOutButton />
              </List>
            </div>
          </div>
        </Drawer>
        <BoardDialog
          newBoard
          board={{}}
          open={this.state.createFormOpen}
          formCloseHandler={this._handleFormClose}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Header);

import { gql } from '@apollo/client';

export const UpdateGroupMutation = gql`
  mutation UpdateGroupMutation(
    $id: ID!
    $name: String
    $roleIds: [ID!]
    $userIds: [ID!]
    $boardIds: [ID!]
  ) {
    updateGroup(
      id: $id
      group: {
        name: $name
        roleIds: $roleIds
        userIds: $userIds
        boardIds: $boardIds
      }
    ) {
      group {
        id
        name
        roles {
          id
          name
        }
        users {
          id
          name
        }
        boards {
          id
          name
        }
      }
    }
  }
`;

import React, { createContext, useContext, useState } from 'react';

// Create the context
const BoardContext = createContext();

// Create a provider component
export const BoardProvider = ({ children }) => {
  const [bucketsExpandedState, setBucketsExpandedState] = useState({});

  const setInitialBucketsState = (bucket) => {
    setBucketsExpandedState((prevBuckets) => ({
      ...prevBuckets,
      [bucket.id]: {
        isExpanded: false,
        gigs: bucket.gigs.reduce((acc, gig) => {
          acc[gig.id] = false;
          return acc;
        }, {})
      }
    }));
  };

  const setBucketExpandedState = (bucketId, isExpanded) => {
    setBucketsExpandedState((prevBuckets) => ({
      ...prevBuckets,
      [bucketId]: isExpanded
    }));
  };

  const setGigExpandedState = (gigId, bucketId, isExpanded) => {
    setBucketsExpandedState((prevBuckets) => ({
      ...prevBuckets,
      [bucketId]: {
        ...prevBuckets[bucketId],
        gigs: {
          ...prevBuckets[bucketId].gigs,
          [gigId]: isExpanded
        }
      }
    }));
  };

  return (
    <BoardContext.Provider
      value={{
        bucketsExpandedState,
        setBucketExpandedState,
        setGigExpandedState,
        setInitialBucketsState
      }}
    >
      {children}
    </BoardContext.Provider>
  );
};

// Create a hook to use the BoardContext
export const useBoardContext = () => {
  const context = useContext(BoardContext);
  if (!context) {
    throw new Error('useBoardContext must be used within a BoardProvider');
  }
  return context;
};

import { gql } from '@apollo/client';

export const DeleteUserMutation = gql`
  mutation DeleteUserMutation($id: ID!) {
    deleteUser(id: $id) {
      user {
        id
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const GetBoardForEditing = gql`
  query GetBoardForEditing($id: ID!) {
    id
    board(id: $id) {
      id
      name
      profiles {
        id
        name
        firstAndLastName
      }
      masterAccounts {
        id
        name
      }
      users {
        id
        name
        email
      }
      groups {
        id
        name
      }
      locationFilter {
        type
        attr
        values
      }
      returnGigsSetting
      showFirstAndLastName
      textlineGroupUuid
    }
  }
`;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Boards from './components/Boards';
import BoardListing from './components/BoardListing';
import UserListing from './components/UserListing';
import GroupListing from './components/GroupListing';
import GigsByFilter from './components/GigsByFilter';
import { MyBoardsWithBuckets } from './components/MyBoardsWithBuckets';
import Me from './components/Me';
import SignIn from './components/SignIn';
import Header from './components/Header';
import { PrivateRoute } from './PrivateRoute';
import { hasRoles } from './auth';
import {
  MuiThemeProvider,
  createTheme,
  responsiveFontSizes
} from '@material-ui/core/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from './constants';

import './App.css';

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: '#004F50'
      },
      secondary: {
        main: '#00BBBB'
      },
      error: {
        main: '#EF4C35'
      }
    },
    typography: {
      fontFamily: 'canada-type-gibson, sans-serif',
      fontSize: 16,
      subtitle2: {
        fontStyle: 'normal',
        fontWeight: 500,
        letterSpacing: '2px',
        textTransform: 'uppercase'
      }
    }
  })
);

export const App = () => {
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <Router>
        <MuiThemeProvider theme={theme}>
          <Header />
          <Routes>
            <Route
              exact
              path="/"
              element={<PrivateRoute component={Boards} />}
            />
            <Route
              exact
              path="/boards/:boardId"
              element={<PrivateRoute component={MyBoardsWithBuckets} />}
            />
            <Route
              exact
              path="/boards"
              element={<PrivateRoute component={BoardListing} />}
            />
            <Route
              exact
              path="/gigsByFilter/:filter"
              element={<PrivateRoute component={GigsByFilter} />}
            />
            <Route
              exact
              path="/bucketsByPriority/:priority"
              element={<PrivateRoute component={MyBoardsWithBuckets} />}
            />
            <Route
              exact
              path="bucketsByPriority/:priority/:rule"
              element={<PrivateRoute component={MyBoardsWithBuckets} />}
            />
            <Route exact path="/me" element={<PrivateRoute component={Me} />} />
            {hasRoles(['admin', 'dashboard_admin']) && (
              <Route
                exact
                path="/users/"
                element={<PrivateRoute component={UserListing} />}
              />
            )}
            {hasRoles(['admin', 'dashboard_admin']) && (
              <Route
                exact
                path="/groups/"
                element={<PrivateRoute component={GroupListing} />}
              />
            )}
            <Route exact path="/signin" component={SignIn} />
            <Route render={() => <div>404</div>} />
          </Routes>
        </MuiThemeProvider>
      </Router>
    </GoogleOAuthProvider>
  );
};

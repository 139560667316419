import { createFragmentRegistry } from '@apollo/client/cache';

import { GigFragments } from '../queries/fragments/gigFragments';
import { BucketFragments } from '../queries/fragments/bucketFragments';
import { GigEventFragments } from '../queries/fragments/gigEventFragments';
import { GigLocationFragments } from '../queries/fragments/gigLocationFragments';
import { BoardFragments } from '../queries/fragments/boardFragments';
import { NoteFragments } from '../queries/fragments/noteFragments';
import { DriverFragments } from '../queries/fragments/driverFragments';
import { BidFragments } from '../queries/fragments/bidFragments';

export const ApolloFragmentRegistry = {
  fragments: createFragmentRegistry(
    ...[
      ...GigFragments,
      ...BucketFragments,
      ...BoardFragments,
      ...GigEventFragments,
      ...NoteFragments,
      ...GigLocationFragments,
      ...DriverFragments,
      ...BidFragments
    ]
  )
};

import { gql } from '@apollo/client';

export const DeleteBoardMutation = gql`
  mutation DeleteBoardMutation($id: ID!) {
    deleteBoard(id: $id) {
      board {
        id
      }
    }
  }
`;

import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { loadedGigsCountByBucket } from '../config/apollo-type-policies';

export const BucketCount = ({ bucketId, totalGigCount }) => {
  const loadedGigsCount =
    useReactiveVar(loadedGigsCountByBucket)[bucketId] ?? 0;

  const bucketHasMoreGigs = loadedGigsCount < totalGigCount;
  const gigCount =
    loadedGigsCount > 0 && bucketHasMoreGigs
      ? `${loadedGigsCount} of ${totalGigCount}`
      : `${totalGigCount}`;
  const gigCountDisplay = `${gigCount} ${gigCount > 1 || gigCount === 0 ? 'gigs' : 'gig'}`;

  return <Typography variant="h6">{gigCountDisplay}</Typography>;
};

import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  ApolloProvider,
  from
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { AUTH_TOKEN, GRAPHQL_API, SENTRY_DSN, SENTRY_ENV } from './constants';
import * as Sentry from '@sentry/react';

import {
  ApolloTypePolicies,
  ClientSideSchema
} from './config/apollo-type-policies';
import { ApolloFragmentRegistry } from './config/apollo-fragment-registry';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV,
  integrations: [Sentry.replayIntegration()],
  tracesSampleRate: 1.0
});

const httpLink = new HttpLink({ uri: GRAPHQL_API });

const errorControl = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      Sentry.captureException(graphQLErrors);
      if (process.env.NODE_ENV === 'development') {
        // Adds messages only in a dev environment
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      }
    });
  }
  if (networkError) {
    Sentry.captureException(networkError);
    if (process.env.NODE_ENV === 'development') {
      // Adds messages only in a dev environment
      console.log(`[Network error]: Message: ${networkError}`);
    }
  }
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: localStorage.getItem(AUTH_TOKEN) || null
    }
  });
  return forward(operation);
});

if (process.env.NODE_ENV === 'development') {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

export const client = new ApolloClient({
  link: from([errorControl, authMiddleware, httpLink]),
  cache: new InMemoryCache({
    ...ApolloFragmentRegistry,
    typePolicies: ApolloTypePolicies,
    typeDefs: ClientSideSchema
  })
});

const root = createRoot(document.getElementById('root'));

root.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

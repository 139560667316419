import React, { Component } from 'react'
import UserForm from './UserForm'

class CreateUser extends Component {
  render() {
    const { closeHandler, userMutation } = this.props
    return(
      <UserForm
        newUser={ true }
        user={ {} }
        userMutation={ userMutation }
        closeHandler={ closeHandler }
      />
    )
  }
}

export default CreateUser

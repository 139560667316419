import { gql } from '@apollo/client';

const BUCKET_FRAGMENT = gql`
  fragment BucketFragment on Bucket {
    title
    gigCount
    description
    color
  }
`;

const BUCKET_WITH_FILTERED_GIGS_COUNT_FRAGMENT = gql`
  fragment BucketWithFilteredGigsCountFragment on Bucket {
    id
    ...BucketFragment
    filteredGigCount(filter: $filter)
    gigs(filter: $filter) {
      id
    }
  }
`;

const BUCKET_WITH_GIGS_FRAGMENT = gql`
  fragment BucketWithGigsFragment on Bucket {
    id
    ...BucketFragment
    gigs(
      order: $order
      pageNumber: $pageNumber
      recordsPerPage: $recordsPerPage
      filter: $filter
    ) {
      id
      ...GigBasicDetailsFragment
      consolidation {
        id
        ...GigConsolidationDetailsFragment
      }
    }
  }
`;

export const BucketFragments = [
  BUCKET_FRAGMENT,
  BUCKET_WITH_GIGS_FRAGMENT,
  BUCKET_WITH_FILTERED_GIGS_COUNT_FRAGMENT
];

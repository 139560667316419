import { AUTH_TOKEN, CURRENT_USER, SIGNIN_WITH_GOOGLE_URL } from '../constants'
import React, { Component } from 'react'
import { GoogleLogin } from '@react-oauth/google';
import { TextField, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';

const styles = theme => ({});

class SignInForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: ''
    }
  }

  _onChange = event => {
    let value = event.target.value
    let key = event.target.id
    this.setState({ [key]: value })
  };

  _onSubmit = event => {
    event.preventDefault();
    this.props.signIn({
      variables: {
        email: this.state.email,
        password: this.state.password
      }
    });
  };

  responseGoogle = (response) => {
    if (response.error) {
      alert("Failed to sign in with google!");
    } else {

      let tokenBlob = new Blob([
        JSON.stringify({
            credential: response.credential
        }, null, 2)
      ]);

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Access-Control-Allow-Origin': '*'
        }
      };

      axios.post(SIGNIN_WITH_GOOGLE_URL, tokenBlob, config)
        .then(res => {
          if (res.data.signIn.token) {
            localStorage.setItem(AUTH_TOKEN, res.data.signIn.token);
            localStorage.setItem(CURRENT_USER, JSON.stringify(res.data.signIn.user));

            window.location = '/'
          } else {
            alert("User couldn't be found.");
          }

        }).catch(err => {
          console.log(err);
        })
    }
  }

  render() {
    const { classes } = this.props
    return (
      <form onSubmit={this._onSubmit}>
        <TextField
          required
          id="email"
          label="Email"
          className={classes.textField}
          margin="normal"
          fullWidth
          onChange={this._onChange}
        />
        <TextField
          required
          id="password"
          label="Password"
          className={classes.textField}
          margin="normal"
          type="password"
          fullWidth
          onChange={this._onChange}
        />
        <Typography align="right">
          <Button variant="outlined" color="primary" type="submit">
            Sign In
          </Button>
        </Typography>
        <Typography align="right">
          <div style={ { 'margin-left': 'auto', 'margin-right': 0, 'max-width': '200px', 'margin-top': '.5em' }}>
            <GoogleLogin
              buttonText="Sign in with Google"
              onSuccess={this.responseGoogle}
              onFailure={this.responseGoogle}
              auto_select
              flow="auth-code"
            />
          </div>
        </Typography>
      </form>
    )
  }
}
export default withStyles(styles)(SignInForm);

import { gql } from '@apollo/client';

export const GetGigEventLabels = gql`
  query GetGigEventLabels {
    events {
      value
      label
    }
  }
`;

import { gql } from '@apollo/client';

export const UpdateUserMutation = gql`
  mutation UpdateUserMutation(
    $id: ID!
    $name: String
    $email: String
    $groupIds: [ID!]
    $roleIds: [ID!]
    $boardIds: [ID!]
  ) {
    updateUser(
      id: $id
      user: {
        name: $name
        email: $email
        groupIds: $groupIds
        roleIds: $roleIds
        boardIds: $boardIds
      }
    ) {
      user {
        id
        email
        name
      }
    }
  }
`;

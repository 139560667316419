import { gql } from '@apollo/client';

export const SearchGroups = gql`
  query SearchGroups($search: String!) {
    id
    allGroups(search: $search) {
      id
      name
      boards {
        id
        name
      }
      roles {
        id
        name
      }
    }
  }
`;

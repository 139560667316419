import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import TimestampTableCell from './TimestampTableCell';

import Loader from './Loader';

export const EventListing = ({ loading, error, data }) => {
  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }
  if (data) {
    const {
      events: { nodes }
    } = data?.gig;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Event</TableCell>
            <TableCell align="right">Timestamp</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {nodes.length > 0 &&
            nodes.map((event) => {
              return (
                <TableRow key={event.id}>
                  <TableCell>{event.eventType}</TableCell>
                  <TableCell align="right">
                    <TimestampTableCell timestamp={event.timestamp} />
                  </TableCell>
                </TableRow>
              );
            })}
          {nodes.length === 0 && (
            <TableRow>
              <TableCell colSpan={2}>No events found</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  }
};

import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Mutation } from '@apollo/client/react/components';
import { DeleteUserMutation } from '../mutations/DeleteUser';
import red from '@material-ui/core/colors/red';

const styles = (theme) => ({
  delete: {
    color: red[500]
  }
});

class DeleteUserButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: null
    };
  }

  _onComplete = (async) => {
    this.props.refresh();
  };

  componentDidMount() {
    let { userId } = this.props;
    this.setState({ userId });
  }

  render() {
    const { classes } = this.props;
    const { userId } = this.state;
    return (
      <Mutation
        mutation={DeleteUserMutation}
        variables={{ id: userId }}
        onCompleted={this._onComplete}
      >
        {(deleteMutation, { error, loading }) => (
          <Button
            onClick={() => window.confirm('Are you sure?') && deleteMutation()}
            className={classes.delete}
            disabled={loading}
          >
            Delete
          </Button>
        )}
      </Mutation>
    );
  }
}
export default withStyles(styles)(DeleteUserButton);

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { Button, Typography, Grid, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import yellow from '@material-ui/core/colors/yellow';
import green from '@material-ui/core/colors/green';
import BoardOverview from './BoardOverview';
import Loader from './Loader';
import { MyBoardsHome } from '../queries/myBoards';
import consumer from '../cable';
import { getBoardIds } from '../Helper';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  red: {
    color: red[500]
  },
  orange: {
    color: orange[800]
  },
  yellow: {
    color: yellow[600]
  },
  green: {
    color: green[900]
  },
  critical: {
    backgroundColor: red[100]
  },
  high: {
    backgroundColor: orange[100]
  },
  medium: {
    backgroundColor: yellow[100]
  },
  low: {
    backgroundColor: green[100]
  },
  statsGrid: {
    textAlign: 'center'
  },
  filterButton: {
    textTransform: 'none'
  }
});

const priorities = [
  { title: 'critical', color: 'red' },
  { title: 'high', color: 'orange' },
  { title: 'medium', color: 'yellow' },
  { title: 'low', color: 'green' }
];

const capitalize = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

function Boards(props) {
  const { classes } = props;
  const [socketStatus, setSocketStatus] = useState(false);
  const [boardsId, setBoardsId] = useState([]);
  const [boardData, setBoardData] = useState({
    id: null,
    allBucketCounts: {},
    gigCountsByRule: {},
    myBoards: {}
  });

  useEffect(() => {
    if (boardsId.length > 0 && !socketStatus) {
      consumer.disconnect();
      boardsId.forEach(function (boardId) {
        consumer.subscriptions.create(
          {
            channel: 'BoardsChannel',
            board: boardId
          },
          {
            received: (updatedData) => {
              buildData(updatedData.body);
            }
          }
        );
      });
    }
  });

  function buildData(response) {
    const id = response.id;
    const data = response.data;
    let boards = boardData.myBoards;
    let prioritiesCounts = {};
    let gigCountsByPriorityAndRule = {};
    let gigCountsByRule = {};

    if (boards.filter((board) => board.id === data.id).length > 0) {
      boards.forEach(function (board) {
        if (board.id === data.id) {
          board['counts'] = data['buckets'];
          board['buckets'] = data['buckets'];
        }
      });
    } else {
      boards.push(data);
    }
    boards.forEach(function (board) {
      board['counts'].forEach(function (bucket) {
        bucket['gigCount'] = bucket['gigCount'] ? bucket['gigCount'] : 0;
        prioritiesCounts[bucket['priority']] = prioritiesCounts[
          bucket['priority']
        ]
          ? prioritiesCounts[bucket['priority']] + bucket['gigCount']
          : bucket['gigCount'];
        if (
          gigCountsByPriorityAndRule[bucket['priority']] === null ||
          gigCountsByPriorityAndRule[bucket['priority']] === undefined
        ) {
          gigCountsByPriorityAndRule[bucket['priority']] = {};
        }
        if (
          isNaN(gigCountsByPriorityAndRule[bucket['priority']][bucket['title']])
        ) {
          gigCountsByPriorityAndRule[bucket['priority']][bucket['title']] =
            bucket['gigCount'];
        } else {
          gigCountsByPriorityAndRule[bucket['priority']][bucket['title']] +=
            bucket['gigCount'];
        }
      });
    });
    for (let priority in gigCountsByPriorityAndRule) {
      gigCountsByRule[priority] = Object.entries(
        gigCountsByPriorityAndRule[priority]
      ).map(function (titleCount) {
        return { rule: titleCount[0], count: titleCount[1] };
      });
    }

    let updatedData = {
      id: id,
      allBucketCounts: prioritiesCounts,
      gigCountsByRule: gigCountsByRule,
      myBoards: boards
    };
    setSocketStatus(true);
    setBoardData(updatedData);
  }

  return (
    <Paper square className={classes.root}>
      <Query
        query={MyBoardsHome}
        partialRefetch={true}
        fetchPolicy={'network-only'}
      >
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) return <div>Error</div>;
          if (!boardData.id) {
            setBoardData(data);
            setBoardsId(getBoardIds(data));
          }
          // all good...
          return (
            <div>
              <Grid
                container
                spacing={3}
                className={classes.statsGrid}
                justifyContent="space-evenly"
              >
                {boardData.id &&
                  priorities.map((priority) => {
                    return (
                      <Grid item xs={3}>
                        <Button
                          component={Link}
                          to={'/bucketsByPriority/' + priority.title}
                          className={classes.filterButton}
                        >
                          <Typography
                            variant="h5"
                            className={classes[priority.color]}
                          >
                            {boardData.allBucketCounts[priority.title]}{' '}
                            {capitalize(priority.title)}
                          </Typography>
                        </Button>
                      </Grid>
                    );
                  })}
              </Grid>
              <Grid
                container
                spacing={1}
                className={classes.statsGrid}
                justifyContent="space-evenly"
                style={{ marginTop: -16 }}
              >
                {boardData.id &&
                  priorities.map((priority) => {
                    return (
                      <Grid item xs={3} style={{ marginBottom: 16 }}>
                        {boardData.gigCountsByRule[priority.title].map(
                          (ruleCount) => {
                            return (
                              <Typography variant="body1">
                                <Button
                                  component={Link}
                                  to={
                                    '/bucketsByPriority/' +
                                    priority.title +
                                    '/' +
                                    ruleCount.rule
                                  }
                                >
                                  <Typography
                                    variant="body1"
                                    style={{ color: 'grey' }}
                                  >
                                    {ruleCount.rule}: {ruleCount.count}
                                  </Typography>
                                </Button>
                              </Typography>
                            );
                          }
                        )}
                      </Grid>
                    );
                  })}
              </Grid>

              <Grid container spacing={3}>
                {boardData.id &&
                  boardData.myBoards.map((board) => {
                    return (
                      <Grid item xs={12} sm={6} md={3} key={board.id}>
                        <BoardOverview board={board} />
                      </Grid>
                    );
                  })}
              </Grid>
            </div>
          );
        }}
      </Query>
    </Paper>
  );
}

export default withStyles(styles)(Boards);

import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Mutation } from '@apollo/client/react/components';
import { DeleteGroupMutation } from '../mutations/DeleteGroup';
import red from '@material-ui/core/colors/red';

const styles = (theme) => ({
  delete: {
    color: red[500]
  }
});

class DeleteGroupButton extends Component {
  state = {
    groupId: null
  };

  _onComplete = (async) => {
    this.props.refresh();
  };

  componentDidMount() {
    let { groupId } = this.props;
    this.setState({ groupId });
  }

  render() {
    const { classes } = this.props;
    const { groupId } = this.state;
    return (
      <Mutation
        mutation={DeleteGroupMutation}
        variables={{ id: groupId }}
        onCompleted={this._onComplete}
      >
        {(deleteMutation, { error, loading }) => (
          <Button
            onClick={() => window.confirm('Are you sure?') && deleteMutation()}
            className={classes.delete}
            disabled={loading}
          >
            Delete
          </Button>
        )}
      </Mutation>
    );
  }
}
export default withStyles(styles)(DeleteGroupButton);

import { gql } from '@apollo/client';

export const CreateGroupMutation = gql`
  mutation CreateGroupMutation(
    $name: String
    $roleIds: [ID!]
    $userIds: [ID!]
    $boardIds: [ID!]
  ) {
    createGroup(
      group: {
        name: $name
        roleIds: $roleIds
        userIds: $userIds
        boardIds: $boardIds
      }
    ) {
      group {
        id
        name

        roles {
          id
          name
        }
        users {
          id
          name
        }
        boards {
          id
          name
        }
      }
    }
  }
`;

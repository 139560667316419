export const AUTH_TOKEN = 'auth-token';
export const CURRENT_USER = 'current_user';
export const RED_COLOR = '#ef9a9a';
export const ORANGE_COLOR = '#ffcc80';
export const YELLOW_COLOR = '#fff59d';
export const GREEN_COLOR = '#a5d6a7';
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const GRAPHQL_API = process.env.REACT_APP_API_URL;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const SIGNIN_WITH_GOOGLE_URL =
  process.env.REACT_APP_SIGNIN_WITH_GOOGLE_URL;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV;
export const CABLE_API = process.env.REACT_APP_CABLE_URL;

export const NEW_RELIC_LICENSE_KEY =
  process.env.REACT_APP_NEW_RELIC_LICENSE_KEY;
export const NEW_RELIC_APP_ID = process.env.REACT_APP_NEW_RELIC_APP_ID;

export const GOOGLE_MAPS_PICKUP_ICON = '/img/primary-pickup-icon.png'; // '//cdn.roadie.com/web-app/pickup-icon@2x.png'
export const GOOGLE_MAPS_DELIVERY_ICON = '/img/primary-delivery-icon.png'; // '//cdn.roadie.com/web-app/delivery-icon@2x.png'
export const GOOGLE_MAPS_PACKAGE_ICON = '/img/primary-package-icon.png'; // '//cdn.roadie.com/web-app/roadie-icon-package@2x.png'
export const GOOGLE_MAPS_DRIVER_ICON = '/img/icon-car.png';
export const GOOGLE_MAPS_PICKUP_ICON_SECONDARY =
  '/img/secondary-pickup-icon.png';
export const GOOGLE_MAPS_DELIVERY_ICON_SECONDARY =
  '/img/secondary-delivery-icon.png';
export const GOOGLE_MAPS_PACKAGE_ICON_SECONDARY =
  '/img/secondary-package-icon.png';

export const DELIVERY_ICON = '/img/delivery-icon.png';
export const BATCH_ICON = '/img/batch-icon.png';

export const STATE_SELECTIONS = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
];

export const STATE_SELECTIONS_WITH_LABELS = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode', value: 'land - RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' }
];

export const SORT_OPTIONS = [
  { value: 'lastEvent.timestamp', label: 'Last Event Time' },
  { value: 'deliveryDeadline', label: 'Delivery Deadline' }
];

export const TYPE_OPTIONS = [
  {
    label: 'Return Gigs',
    filter: {
      returnGig: true
    }
  },
  {
    label: 'No Return Gigs',
    filter: {
      returnGig: false
    }
  }
];

export const ORDER_BY = [
  { value: 'asc', label: 'Earliest First' },
  { value: 'desc', label: 'Latest First' }
];

export const OPERATORS = {
  none: {
    name: 'none',
    selectLabel: <em>None</em>,
    selectText: () => 'Select...'
  },
  greater: {
    name: 'greater',
    valueLabel: 'Declared Value Min ($)',
    selectLabel: 'Greater Than or Equal To',
    selectText: ({ declaredValueMin }) => `>= $${declaredValueMin}`
  },
  less: {
    name: 'less',
    valueLabel: 'Declared Value Max ($)',
    selectLabel: 'Less Than or Equal To',
    selectText: ({ declaredValueMax }) => `<= $${declaredValueMax}`
  },
  between: {
    name: 'between',
    selectLabel: 'Between',
    selectText: ({ declaredValueMin, declaredValueMax }) =>
      `Between $${declaredValueMin} and $${declaredValueMax}`
  }
};

export const CUSTOM_EVENT_DISPLAY_NAMES = [
  {
    original_name: 'Roadie on way to pickup',
    display_name: 'Driver on Way to Pickup'
  },
  { original_name: 'Roadie at pickup', display_name: 'Driver At Pickup' },
  {
    original_name: 'Roadie on way to dropoff',
    display_name: 'Driver on Way to Dropoff'
  },
  { original_name: 'Roadie at dropoff', display_name: 'Driver at Dropoff' },
  { original_name: 'Pickup verified', display_name: 'Driver Verified' }
];

export const GIGS_BY_BUCKET_PAGINATION_LIMIT = 15;

export const POLLING_INTERVALS = {
  BOARD: 300000,
  BUCKET: 300000,
  BUCKET_EXPANDED: 60000,
  GIG: 60000
};

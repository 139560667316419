import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Paper, useTheme } from '@material-ui/core';
import { useParams, useLocation } from 'react-router-dom';

import { POLLING_INTERVALS } from '../constants';

import {
  MyBoardsWithBucketsByPriorityAndRule,
  MyBoardsWithBucketsQuery
} from '../queries/myBoards';

import { useGigFilterAndSort } from '../hooks/useGigFilterAndSort';

import Loader from './Loader';
import { BoardWithBuckets } from './BoardWithBuckets';
import GigFilters from './GigFilters/GigFilters';

import { getNonEmptyBoards } from '../Helper';
import { BoardProvider } from '../hooks/useBoardContext';

const useStyles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxShadow: 'unset'
  }
});

export const MyBoardsWithBuckets = () => {
  const { rule, priority, boardId } = useParams();
  const { pathname } = useLocation();
  const theme = useTheme();
  const classes = useStyles(theme);

  const [boards, setBoards] = useState([]);

  const isStandardBoard = pathname.indexOf('/boards/') !== -1 && !!boardId;

  const { appliedFilters, handleFilterChange } = useGigFilterAndSort();

  const query = isStandardBoard
    ? MyBoardsWithBucketsQuery
    : MyBoardsWithBucketsByPriorityAndRule;

  const vars = isStandardBoard
    ? { boardId, filter: appliedFilters }
    : { priority, title: rule ? rule : null, filter: appliedFilters };

  const { data, loading, error } = useQuery(query, {
    variables: vars,
    nextFetchPolicy: 'network-only',
    pollInterval: POLLING_INTERVALS.myBoards
  });

  useEffect(() => {
    if (data) {
      if (isStandardBoard) {
        setBoards(getNonEmptyBoards([data?.board]));
      } else {
        setBoards(getNonEmptyBoards(data?.myBoards, 'bucketsByPriority'));
      }
    }
  }, [data, isStandardBoard]);

  return (
    <Paper
      square
      className={`${classes.root}`}
      style={{ boxShadow: 'unset', paddingTop: 12 }}
    >
      <GigFilters
        appliedFilters={appliedFilters}
        handleApplyFilters={handleFilterChange}
      />
      {loading && <Loader />}
      {error && <div>{error.message}</div>}
      {!loading &&
        !error &&
        boards.length > 0 &&
        boards.map((board) => (
          <BoardProvider>
            <BoardWithBuckets
              key={board.id}
              board={board}
              priority={priority}
              rule={rule}
              isStandardBoard={isStandardBoard}
              appliedFilters={appliedFilters}
            />
          </BoardProvider>
        ))}
    </Paper>
  );
};

import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Divider,
  Hidden,
  Grid
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

import TimestampTableCell from './TimestampTableCell';
import Loader from './Loader';
import { getCertsDisplayLabel } from '../Helper';

const styles = () => ({
  Accepted: {
    backgroundColor: green[50]
  },
  Cancelled: {
    backgroundColor: red[50]
  }
});

const Timestamp = ({ timestamp }) => (
  <Typography variant="caption" display="block" noWrap>
    <Moment format="(M/D h:mma)">{timestamp}</Moment>
  </Typography>
);

const BidTooltip = ({ certMap }) => {
  return certMap && certMap.length > 0
    ? certMap.map((cert) => (
        <p>
          {cert.name}: {cert.checked ? '✓' : 'x'}
        </p>
      ))
    : null;
};

const BidListing = ({ loading, error, data, gigId, classes }) => {
  const handleRowClick = (url) => {
    window.open(url, '_blank'); // Opens the URL in a new tab
  };

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }
  if (data) {
    const { bids: offersData } = data?.gig;
    const offers = offersData.nodes || [];
    return (
      <Table>
        <Hidden smDown>
          <TableHead>
            <TableRow>
              <TableCell>Driver</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Vehicle</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Certs</TableCell>
            </TableRow>
          </TableHead>
        </Hidden>
        <TableBody>
          {offers.map((offer) => {
            const {
              id,
              status = null,
              acceptedAt = null,
              cancelledAt = null,
              profile = null,
              vehicle = null,
              timestamp = null,
              hasRequiredCerts = null,
              cancellationCategory = null,
              rejectionReason = null,
              certMap = []
            } = offer;
            const adminLink = `https://admin.roadie.com/gigs/${gigId}/offers/${id}`;

            const StatusCell = () => {
              const statuses = {
                Accepted: {
                  timestamp: acceptedAt
                },
                Cancelled: {
                  timestamp: cancelledAt,
                  caption: cancellationCategory
                },
                default: {
                  caption: rejectionReason
                }
              };

              const { timestamp, caption } =
                statuses[status] || statuses.default;
              return (
                <div>
                  {status}
                  {timestamp && <Timestamp timestamp={timestamp} />}
                  {caption && (
                    <Typography variant="caption">
                      <br />
                      <em>{caption}</em>
                    </Typography>
                  )}
                </div>
              );
            };

            return (
              <TableRow
                key={offer.id}
                hover
                style={{
                  cursor: 'pointer'
                }}
                onClick={() => handleRowClick(adminLink)}
                className={classes[status]}
              >
                <Hidden xsDown>
                  <TableCell>{profile?.name || ''}</TableCell>
                  <TableCell>
                    <StatusCell />
                  </TableCell>
                  <TableCell>{vehicle?.friendlyName || ''}</TableCell>
                  <TableCell align="left">
                    {timestamp && <TimestampTableCell timestamp={timestamp} />}
                  </TableCell>

                  <TableCell>
                    {hasRequiredCerts && (
                      <Tooltip title={<BidTooltip certMap={certMap} />}>
                        <span>{getCertsDisplayLabel(hasRequiredCerts)}</span>
                      </Tooltip>
                    )}
                  </TableCell>
                </Hidden>
                <Hidden smUp>
                  <TableCell>
                    <Grid container>
                      <Grid item xs={6}>
                        {profile?.name || ''}
                        <br />
                        <Typography variant="caption">
                          <StatusCell />
                        </Typography>
                      </Grid>
                      <Grid item xs={6} align="right">
                        {timestamp && (
                          <Typography variant="caption" display="block" noWrap>
                            <Moment format="(M/D h:mma)">{timestamp}</Moment>
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                        <Typography variant="caption">
                          {vehicle?.friendlyName || ''}
                        </Typography>
                        <Typography variant="caption">
                          {hasRequiredCerts && (
                            <Tooltip title={<BidTooltip certMap={certMap} />}>
                              <span>
                                {getCertsDisplayLabel(hasRequiredCerts)}
                              </span>
                            </Tooltip>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </Hidden>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
};

export default withStyles(styles)(BidListing);

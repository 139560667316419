import React, { useRef, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { OPERATORS } from '../../constants';

import { useOutsideClickHandler } from '../../hooks/useOutsideClickHandler';

export const GigValueFilter = ({ title, onClickApply }) => {
  const [open, setOpen] = useState(false);
  const [operator, setOperator] = useState(OPERATORS.none);
  const [declaredValueMin, setDeclaredValueMin] = useState('');
  const [declaredValueMax, setDeclaredValueMax] = useState('');
  const [canApplyFilter, setCanApplyFilter] = useState(false);

  const outsideClickCb = () => setOpen(false);

  const selectRef = useRef(null);
  const dropdownRef = useRef(null);
  const innerDropdownRef = useRef(null);

  useOutsideClickHandler(
    [selectRef, dropdownRef, innerDropdownRef],
    outsideClickCb
  );

  useEffect(() => {
    const isValidField = (field) => !isNaN(field) && field !== '';

    if (operator.name === 'none') {
      setCanApplyFilter(false);
    } else if (operator.name === 'greater') {
      setCanApplyFilter(isValidField(declaredValueMin));
    } else if (operator.name === 'less') {
      setCanApplyFilter(isValidField(declaredValueMax));
    } else if (operator.name === 'between') {
      setCanApplyFilter(
        isValidField(declaredValueMin) &&
          isValidField(declaredValueMax) &&
          declaredValueMin < declaredValueMax
      );
    }
  }, [operator, declaredValueMin, declaredValueMax]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const reset = () => {
    setDeclaredValueMin('');
    setDeclaredValueMax('');
    setOperator(OPERATORS.none);
  };

  const handleOperatorChange = (event) => {
    setOperator(event.target.value);
  };

  const handleValueChangeMin = (event) => {
    setDeclaredValueMin(event.target.value);
  };

  const handleValueChangeMax = (event) => {
    setDeclaredValueMax(event.target.value);
  };

  const handleClickApply = () => {
    const min =
      !isNaN(declaredValueMin) &&
      (operator.name === 'between' || operator.name === 'greater')
        ? { declaredValueMin: Number(declaredValueMin) }
        : { declaredValueMin: null };
    const max =
      !isNaN(declaredValueMax) &&
      (operator.name === 'between' || operator.name === 'less')
        ? { declaredValueMax: Number(declaredValueMax) }
        : { declaredValueMax: null };
    onClickApply({ ...min, ...max });
    handleClose();
  };

  const handleClickClear = () => {
    reset();
    onClickApply({ declaredValueMin: null, declaredValueMax: null });
    handleClose();
  };

  const handleMenuRef = (element) => {
    if (element) {
      dropdownRef.current = element;
    }
  };

  const MenuProps = {
    PaperProps: {
      ref: handleMenuRef // Attach ref to the Paper element (the dropdown content)
    }
  };

  const handleInnerMenuRef = (element) => {
    if (element) {
      innerDropdownRef.current = element;
    }
  };

  const InnerMenuProps = {
    PaperProps: {
      ref: handleInnerMenuRef // Attach ref to the Paper element (the inner dropdown content)
    }
  };

  return (
    <div
      ref={selectRef}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: 5,
        marginRight: 20,
        position: 'relative' // Ensure positioning context
      }}
    >
      <Typography style={{ marginRight: 15 }}>{title} :</Typography>
      <Select
        sx={{ minWidth: 200, maxHeight: 38, backgroundColor: 'white' }}
        value="placeholder"
        open={open}
        onOpen={handleOpen}
        onClick={handleClick} // Prevent dropdown close on click
        MenuProps={MenuProps}
      >
        {!open && (
          <MenuItem value="placeholder" disabled>
            {operator.selectText({ declaredValueMin, declaredValueMax })}
          </MenuItem>
        )}
        <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormControl sx={{ display: 'flex', m: 1, minWidth: 120 }}>
            <InputLabel id="demo-dialog-select-label">Operator</InputLabel>
            <Select
              labelId="demo-dialog-select-label"
              id="demo-dialog-select"
              onChange={handleOperatorChange}
              input={<OutlinedInput label="Select..." />}
              value={operator}
              MenuProps={InnerMenuProps}
            >
              {Object.keys(OPERATORS).map((key) => (
                <MenuItem key={key} value={OPERATORS[key]}>
                  {OPERATORS[key].selectLabel}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '1rem'
            }}
            sx={{ m: 1, minWidth: 120 }}
          >
            {open &&
              (operator.name === 'greater' || operator.name === 'between') && (
                <TextField
                  type="number"
                  value={declaredValueMin}
                  label={operator.valueLabel}
                  variant="outlined"
                  onChange={handleValueChangeMin}
                />
              )}
            {open &&
              (operator.name === 'less' || operator.name === 'between') && (
                <TextField
                  type="number"
                  value={declaredValueMax}
                  label={operator.valueLabel}
                  variant="outlined"
                  onChange={handleValueChangeMax}
                />
              )}
          </FormControl>
          <Button onClick={handleClickClear}>Clear</Button>
          <Button disabled={!canApplyFilter} onClick={handleClickApply}>
            Apply
          </Button>
        </Box>
      </Select>
    </div>
  );
};

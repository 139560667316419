import { gql } from '@apollo/client';

export const UpdateBoardMutation = gql`
  mutation UpdateBoardMutation(
    $id: ID!
    $name: String
    $profileIds: [ID!]
    $masterAccountIds: [ID!]
    $userIds: [ID!]
    $groupIds: [ID!]
    $settings: Json
    $locationFilter: LocationFilterInput
    $returnGigsSetting: Int
    $showFirstAndLastName: Boolean
    $textlineGroupUuid: String
  ) {
    updateBoard(
      id: $id
      board: {
        name: $name
        settings: $settings
        profileIds: $profileIds
        masterAccountIds: $masterAccountIds
        userIds: $userIds
        groupIds: $groupIds
        locationFilter: $locationFilter
        returnGigsSetting: $returnGigsSetting
        showFirstAndLastName: $showFirstAndLastName
        textlineGroupUuid: $textlineGroupUuid
      }
    ) {
      board {
        id
        name
      }
    }
  }
`;

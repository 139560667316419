import { useEffect } from 'react';

export const useOutsideClickHandler = (refs, callback) => {
  const handleClick = (e) => {
    if (!refs.some((ref) => ref.current && ref.current.contains(e.target))) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => document.removeEventListener('mousedown', handleClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refs, callback]);
};

import { gql } from '@apollo/client';

const DRIVER_BASIC_DETAILS_FRAGMENT = gql`
  fragment DriverBasicDetailsFragment on Profile {
    name
    phoneNumber
    assignedConsolidationsCount
    assignedGigsCount
    valueOfCurrentlyAcceptedGigs
  }
`;

const DRIVER_FRAGMENT = gql`
  fragment DriverExtendedDetailsFragment on Profile {
    firstAndLastName
    masterAccount {
      id
      name
    }
    assignedConsolidations {
      id
      ...GigConsolidationDetailsFragment
    }
  }
`;

export const DriverFragments = [DRIVER_BASIC_DETAILS_FRAGMENT, DRIVER_FRAGMENT];

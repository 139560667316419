import { gql } from '@apollo/client';

const GIG_CONSOLIDATION_EXTENDED_DETAILS_FRAGMENT = gql`
  fragment GigConsolidationDetailsFragment on Consolidation {
    state
    totalValue
    estimatedDriveTime
    estimatedDistance
    routePolyline
    notes {
      totalCount
    }
  }
`;

const CONSOLIDATION_GIG_FRAGMENT = gql`
  fragment ConsolidationGigFragment on ConsolidationGig {
    title
    state
    lastEvent {
      ...GigEventFragment
    }
    pickupLocation {
      id
      ...GigLocationFragment
    }
    deliveryLocation {
      id
      ...GigLocationFragment
    }
  }
`;

const GIG_BASIC_FRAGMENT = gql`
  fragment GigBasicDetailsFragment on Gig {
    title
    state
    deliveryDeadline
    distance
    referenceId
    declaredValue
    consolidationPosition
    consolidationDeliveryCount
    assignedConsolidationsOrder
    returnGig
    driverExpectation
    dynamicEtaTimestamp
    dynamicEtaDistanceRemaining
    recipient {
      id
      name
    }
    lastEvent {
      ...GigEventFragment
    }
  }
`;

const GIG_EXTENDED_FRAGMENT = gql`
  fragment GigExtendedDetailsFragment on Gig {
    notes {
      totalCount
    }
    events {
      totalCount
      nodes {
        ...GigEventFragment
      }
    }
    bids {
      totalCount
    }
    sender {
      id
      name
      firstAndLastName
      masterAccount {
        id
        name
      }
    }
    consolidation {
      id
      ...GigConsolidationDetailsFragment
      consolidationGigs {
        id
        ...ConsolidationGigFragment
      }
    }
    driver {
      id
      ...DriverBasicDetailsFragment
      ...DriverExtendedDetailsFragment
    }
  }
`;

const GIG_MAP_FRAGMENT = gql`
  fragment GigMapFragment on Gig {
    pickupLocation {
      id
      ...GigLocationFragment
    }
    deliveryLocation {
      id
      ...GigLocationFragment
    }
    driverLastPosition {
      id
      latitude
      longitude
      timestamp
    }
    driverPolyline
    consolidationDeliveryCount

    routeData {
      id
      polyline
    }

    consolidation {
      id
      ...GigConsolidationDetailsFragment
      consolidationGigs {
        id
        ...ConsolidationGigFragment
      }
    }

    driver {
      id
      ...DriverBasicDetailsFragment
      ...DriverExtendedDetailsFragment
    }
  }
`;

export const GigFragments = [
  GIG_BASIC_FRAGMENT,
  GIG_EXTENDED_FRAGMENT,
  GIG_CONSOLIDATION_EXTENDED_DETAILS_FRAGMENT,
  CONSOLIDATION_GIG_FRAGMENT,
  GIG_MAP_FRAGMENT
];

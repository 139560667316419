import React, { Component } from 'react';
import { TextField, Button, Typography , DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ApolloConsumer } from '@apollo/client';
import AutocompleteSearch from './AutocompleteSearch';

const styles = (theme) => ({
  root: {
    overflow: 'visible'
  },
  divider: {
    height: theme.spacing(2)
  },
  autocomplete: {
    height: 'auto'
  }
});

class UserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inheritedRoles: props.user.inheritedRoles || [],
      inheritedBoards: props.user.inheritedBoards || []
    };
  }

  _onChange = (event) => {
    let value = event.target.value;
    let key = event.target.id;
    this.setState({ [key]: value });
  };

  _groupChange = (value, action) => {
    let inheritedRoles = value.map((v) => v.roles).flat();
    let inheritedBoards = value.map((v) => v.boards).flat();
    this.setState({
      group_ids: value.map((v) => v.value),
      inheritedRoles: inheritedRoles,
      inheritedBoards: inheritedBoards
    });
  };

  _roleChange = (value, action) => {
    this.setState({ role_ids: value.map((v) => v.value) });
  };

  _boardChange = (value, action) => {
    this.setState({ board_ids: value.map((v) => v.value) });
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.props.userMutation({
      variables: {
        id: this.props.userId,
        name: this.state.name,
        email: this.state.email,
        password: this.state.password,
        groupIds: this.state.group_ids,
        roleIds: this.state.role_ids,
        boardIds: this.state.board_ids
      }
    });
  };

  render() {
    const { classes, closeHandler, user, newUser } = this.props;
    let { inheritedRoles, inheritedBoards } = this.state;
    let password;

    if (newUser) {
      password = (
        <div>
          <div className={classes.divider} />
          <TextField
            required
            id="password"
            label="Password"
            className={classes.textField}
            margin="normal"
            type="password"
            fullWidth
            onChange={this._onChange}
            defaultValue={user.password}
          />
        </div>
      );
    }

    let inheritedRoleList;
    if (inheritedRoles.flat().length > 0) {
      inheritedRoleList = (
        <Typography variant="caption">
          Inherited from groups:{' '}
          {inheritedRoles
            .filter(Boolean)
            .map((v) => v.name)
            .join(', ')}
        </Typography>
      );
    }

    let inheritedBoardList;
    if (inheritedBoards.length > 0) {
      inheritedBoardList = (
        <Typography variant="caption">
          Inherited from groups:{' '}
          {inheritedBoards
            .filter(Boolean)
            .map((v) => v.name)
            .join(', ')}
        </Typography>
      );
    }

    return (
      <form onSubmit={this.onSubmit}>
        <DialogTitle id="form-dialog-title">Edit User: {user.name}</DialogTitle>
        <DialogContent className={classes.root}>
          <div>
            <TextField
              required
              id="name"
              label="Name"
              className={classes.textField}
              margin="normal"
              fullWidth
              autoFocus
              onChange={this._onChange}
              defaultValue={user.name}
            />
            <div className={classes.divider} />
            <TextField
              required
              id="email"
              label="Email"
              className={classes.textField}
              margin="normal"
              fullWidth
              onChange={this._onChange}
              defaultValue={user.email}
            />
            {password}
            <div className={classes.divider} />
            <ApolloConsumer>
              {(client) => (
                <div>
                  <AutocompleteSearch
                    id="group_ids"
                    label="Groups"
                    value={user.groups}
                    client={client}
                    modelType="Group"
                    onChange={this._groupChange}
                    className={classes.autocomplete}
                  />
                  <div className={classes.divider} />
                  <AutocompleteSearch
                    id="role_ids"
                    label="Roles"
                    value={user.roles}
                    client={client}
                    modelType="Role"
                    onChange={this._roleChange}
                    className={classes.autocomplete}
                  />
                  {inheritedRoleList}
                  <div className={classes.divider} />
                  <AutocompleteSearch
                    id="board_ids"
                    label="Boards"
                    value={user.boards}
                    client={client}
                    modelType="Board"
                    onChange={this._boardChange}
                    className={classes.autocomplete}
                  />
                  {inheritedBoardList}
                </div>
              )}
            </ApolloConsumer>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" type="submit">
            Submit
          </Button>
          <Button variant="outlined" onClick={closeHandler}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    );
  }
}
export default withStyles(styles)(UserForm);

import React, { useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Checkbox,
  FormControlLabel
, DialogTitle, DialogContent, DialogActions ,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Chip
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ApolloConsumer, useQuery } from '@apollo/client';
import AutocompleteSearch from './AutocompleteSearch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { STATE_SELECTIONS } from '../constants';
import Loader from './Loader';

import query from '../queries/getBoardSettings'
import { set } from 'lodash';

const styles = (theme) => ({
  root: {
    overflow: 'visible'
  },
  divider: {
    height: theme.spacing(2)
  },
  autocomplete: {
    height: 'auto'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
});

const emptyLocationFilter = {
  type: '',
  attr: '',
  values: []
};

const BoardForm = ({ classes, board, boardId, boardMutation, closeHandler }) => {
  const [name, setName] = useState(board.name);
  const [locationFilter, setLocationFilter] = useState(board.locationFilter || emptyLocationFilter);
  const [returnGigsSetting, setReturnGigsSetting] = useState(board.returnGigsSetting);
  const [showFirstAndLastName, setShowFirstAndLastName] = useState(board.showFirstAndLastName || false);
  const [textlineGroupUuid, setTextlineGroupUuid] = useState(board.textlineGroupUuid);
  const [profileIds, setProfileIds] = useState(board?.profiles?.map((v) => v.id));
  const [masterAccountIds, setMasterAccountIds] = useState(board?.masterAccounts?.map((v) => v.id));
  const [userIds, setUserIds] = useState(board?.users?.map((v) => v.id));
  const [groupIds, setGroupIds] = useState(board?.groups?.map((v) => v.id));

  const { loading, error, data } = useQuery(query);

  if (loading) return(<Loader/>);
  if (error) return <p>Error: {error.message}</p>;

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;

    const stateSetters = {
      return_gigs_setting: setReturnGigsSetting,
      textlineGroupUuid: setTextlineGroupUuid,
    };

    const setter = stateSetters[name];
    if (setter) {
      setter(value === '' ? undefined : value);
    }
  };

  const handleProfileChange = (value) => {
    setProfileIds(value.map((v) => v.value));
  };

  const handleMasterAccountChange = (value) => {
    setMasterAccountIds(value.map((v) => v.value));
  };

  const handleUserChange = (value) => {
    setUserIds(value.map((v) => v.value));
  };

  const handleGroupChange = (value) => {
    setGroupIds(value.map((v) => v.value));
  };

  const handleLocationFilterChange = (event) => {
    const { name, value } = event.target;
    if (value === '') {
      updateLocationFilter('attr', '');
      updateLocationFilter('values', []);
    }
    updateLocationFilter(name, value);
  };

  const handleLocationFilterValuesChange = (event, value) => {
    updateLocationFilter('values', value);
  };

  const updateLocationFilter = (key, value) => {
    setLocationFilter({ ...locationFilter, [key]: value });
  };

  const filterValuesOptions = () => {
    return locationFilter.attr === 'state' ? STATE_SELECTIONS : [];
  };

  const isAttrFilterDisabled = () => {
    return locationFilter.type === '';
  };

  const isValuesFilterDisabled = () => {
    return (
      locationFilter.type === '' ||
      locationFilter.attr === ''
    );
  };

  const handleCheckboxChange = (event) => {
    setShowFirstAndLastName(event.target.checked);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const updatedLocationFilter =
      locationFilter.type === '' ||
      locationFilter.attr === '' ||
      locationFilter.values.length === 0
        ? null
        : (({ __typename, ...rest }) => rest)(locationFilter);

    boardMutation({
      variables: {
        id: boardId,
        name: name,
        settings: board.settings,
        profileIds: profileIds,
        masterAccountIds: masterAccountIds,
        userIds: userIds,
        groupIds: groupIds,
        locationFilter: updatedLocationFilter,
        returnGigsSetting: returnGigsSetting,
        showFirstAndLastName: showFirstAndLastName,
        textlineGroupUuid: textlineGroupUuid,
      },
    });
  };


    return (
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title">Edit Board: {board.name}</DialogTitle>
        <DialogContent className={classes.root}>
          <div>
            <TextField
              required
              id="name"
              label="Name"
              margin="normal"
              fullWidth
              autoFocus
              onChange={handleNameChange}
              defaultValue={board.name}
            />
            <div className={classes.divider} />
            <ApolloConsumer>
              {(client) => (
                <div>
                  <AutocompleteSearch
                    id="profile_ids"
                    label="Profiles"
                    value={board.profiles}
                    client={client}
                    modelType="Profile"
                    onChange={handleProfileChange}
                    className={classes.autocomplete}
                    showFirstAndLastName={showFirstAndLastName}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showFirstAndLastName}
                        onChange={handleCheckboxChange}
                        color="primary"
                      />
                    }
                    label="Use First and Last Name"
                  />
                  <div className={classes.divider} />
                  <AutocompleteSearch
                    id="master_account_ids"
                    label="Master Accounts"
                    value={board.masterAccounts}
                    client={client}
                    modelType="MasterAccount"
                    onChange={handleMasterAccountChange}
                    className={classes.autocomplete}
                  />
                </div>
              )}
            </ApolloConsumer>

            <div className={classes.divider} />
            <Typography variant="h6">Assignments</Typography>
            <div className={classes.divider} />
            <ApolloConsumer>
              {(client) => (
                <div>
                  <AutocompleteSearch
                    id="user_ids"
                    label="Users"
                    value={board.users}
                    client={client}
                    modelType="User"
                    onChange={handleUserChange}
                    className={classes.autocomplete}
                  />
                  <div className={classes.divider} />
                  <AutocompleteSearch
                    id="group_ids"
                    label="Groups"
                    value={board.groups}
                    client={client}
                    modelType="Group"
                    onChange={handleGroupChange}
                    className={classes.autocomplete}
                  />
                </div>
              )}
            </ApolloConsumer>
          </div>

          <div className={classes.divider} />

          <Typography variant="h6">Filters</Typography>
          <div style={{ display: 'flex', alignItems: 'center', gap: '100px' }}>
            <div>
              <Typography variant="subtitle1">Location Filter</Typography>
              <FormControl className={classes.formControl}>
                <InputLabel id="locationFilter.type">Location</InputLabel>
                <Select
                  labelId="locationFilter.type"
                  id="locationFilter.type"
                  name="type"
                  value={locationFilter.type}
                  onChange={handleLocationFilterChange}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value={'pickup'}>Pickup Location</MenuItem>
                  <MenuItem value={'delivery'}>Delivery Location</MenuItem>
                </Select>
              </FormControl>

              <FormControl className={classes.formControl}>
                <InputLabel id="locationFilter.attr">Attribute</InputLabel>
                <Select
                  labelId="locationFilter.attr"
                  id="locationFilter.attr"
                  name="attr"
                  value={locationFilter.attr}
                  onChange={handleLocationFilterChange}
                  disabled={isAttrFilterDisabled()}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value={'city'}>Cities</MenuItem>
                  <MenuItem value={'state'}>States</MenuItem>
                  <MenuItem value={'store_number'}>Store Numbers</MenuItem>
                </Select>
              </FormControl>

              <FormControl className={classes.formControl}>
                <Autocomplete
                  multiple
                  id="locationFilter.values"
                  name="values"
                  options={filterValuesOptions()}
                  value={locationFilter.values}
                  freeSolo
                  autoSelect
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Values" />
                  )}
                  onChange={handleLocationFilterValuesChange}
                  disabled={isValuesFilterDisabled()}
                />
              </FormControl>
            </div>
            <div>
              <Typography variant="subtitle1">Return Gigs Filter</Typography>
              <FormControl className={classes.formControl}>
                <InputLabel></InputLabel>
                <Select
                  id="return_gigs_setting"
                  name="return_gigs_setting"
                  defaultValue={
                    board.returnGigsSetting != null
                      ? board.returnGigsSetting
                      : ''
                  }
                  displayEmpty={true}
                  onChange={handleSelectChange}
                >
                  <MenuItem value={''}>None</MenuItem>
                  <MenuItem value={0}>Only Return Gigs</MenuItem>
                  <MenuItem value={1}>Exclude Return Gigs</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={ classes.divider } />
          <Typography variant="h6">Board Settings</Typography>
          <div style={{ display: 'flex', alignItems: 'center', gap: '50px' }}>
          <Typography variant="subtitle1">Select Textline Channel</Typography>
          <FormControl className={ classes.formControl }>
            <Select
              id="textlineGroupUuid"
              name="textlineGroupUuid"
              defaultValue={ textlineGroupUuid != null ? textlineGroupUuid : data.boardSettings?.textlineChannels?.[0]?.["channel_group_uuid"]}
              onChange={ handleSelectChange }
            >          
              {
                data.boardSettings.textlineChannels.map(option => 
                  <MenuItem value={option["channel_group_uuid"]}>
                    {option["channel_name"]}
                  </MenuItem>
                )
              }
            </Select>
          </FormControl>
        </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" type="submit">
            Submit
          </Button>
          <Button variant="outlined" onClick={closeHandler}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    );
  }

export default withStyles(styles)(BoardForm);

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import AsyncSelect from 'react-select/async';
import { SearchProfiles } from '../queries/searchProfiles';
import { SearchMasterAccounts } from '../queries/searchMasterAccounts';
import { SearchUsers } from '../queries/searchUsers';
import { SearchGroups } from '../queries/searchGroups';
import { SearchRoles } from '../queries/searchRoles';
import { SearchBoards } from '../queries/searchBoards';
import {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  selectStyles
} from './SelectOverrideComponents';

const styles = (theme) => selectStyles(theme);

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

const queryModel = (modelType) => {
  if (modelType === 'Profile') {
    return SearchProfiles;
  } else if (modelType === 'MasterAccount') {
    return SearchMasterAccounts;
  } else if (modelType === 'User') {
    return SearchUsers;
  } else if (modelType === 'Group') {
    return SearchGroups;
  } else if (modelType === 'Role') {
    return SearchRoles;
  } else if (modelType === 'Board') {
    return SearchBoards;
  }
};
const search = async (inputValue, modelType, client) => {
  const { data } = await client.query({
    query: queryModel(modelType),
    variables: { search: inputValue }
  });
  let results = [];
  if (modelType === 'Profile') {
    results = data.allProfiles;
  } else if (modelType === 'MasterAccount') {
    results = data.allMasterAccounts;
  } else if (modelType === 'User') {
    results = data.allUsers;
  } else if (modelType === 'Group') {
    results = data.allGroups;
  } else if (modelType === 'Role') {
    results = data.allRoles;
  } else if (modelType === 'Board') {
    results = data.allBoards;
  }
  let options = results.map((result) => ({
    value: result.id,
    label: `${result.name} (${result.id})`,
    boards: result.boards,
    roles: result.roles,
    name: result.name,
    firstAndLastName: result.firstAndLastName
  }));
  return options;
};

class AutocompleteSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onChange: props.onChange,
      selectedValues: this.props.value
        ? this.props.value.map((obj) => ({
            value: obj.id,
            label: obj.name,
            boards: obj.boards,
            roles: obj.roles
          }))
        : [],
      showFirstAndLastName: this.props.showFirstAndLastName
    };
  }

  updateLabel = () => {
    if (this.props.value && this.props.showFirstAndLastName) {
      this.setState({
        selectedValues: this.props.value.map((obj) => ({
          value: obj.id,
          label: `${obj.firstAndLastName} (${obj.id})`,
          boards: obj.boards,
          roles: obj.roles,
          firstAndLastName: obj.firstAndLastName,
          name: obj.name
        }))
      });
    } else if (this.props.value) {
      this.setState({
        selectedValues: this.props.value.map((obj) => ({
          value: obj.id,
          label: `${obj.name} (${obj.id})`,
          boards: obj.boards,
          roles: obj.roles,
          firstAndLastName: obj.firstAndLastName,
          name: obj.name
        }))
      });
    }
  };

  updateNewSearchLabel = () => {
    if (this.state.selectedValues && this.props.showFirstAndLastName) {
      this.setState({
        selectedValues: this.state.selectedValues.map((obj) => ({
          value: obj.value,
          label: `${obj.firstAndLastName} (${obj.value})`,
          boards: obj.boards,
          roles: obj.roles,
          firstAndLastName: obj.firstAndLastName,
          name: obj.name
        }))
      });
    } else if (this.state.selectedValues) {
      this.setState({
        selectedValues: this.state.selectedValues.map((obj) => ({
          value: obj.value,
          label: `${obj.name} (${obj.value})`,
          boards: obj.boards,
          roles: obj.roles,
          firstAndLastName: obj.firstAndLastName,
          name: obj.name
        }))
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.showFirstAndLastName !== this.props.showFirstAndLastName) {
      this.updateLabel();
      this.updateNewSearchLabel();
    }
  }

  componentDidMount() {
    this.updateLabel();
  }

  handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue }, this.loadOptions);
  };

  loadOptions = (inputValue) => {
    if (inputValue && inputValue.length > 2) {
      return search(inputValue, this.props.modelType, this.props.client).then(
        (options) => {
          return options;
        }
      );
    }
  };

  handleSelect = (selectedOption) => {
    this.state.onChange(selectedOption);

    this.setState({
      selectedValues: selectedOption.map((obj) => ({
        value: obj.value,
        label: obj.label,
        boards: obj.boards,
        roles: obj.roles,
        firstAndLastName: obj.firstAndLastName,
        name: obj.name
      }))
    });
  };

  render() {
    const { classes, label } = this.props;
    const selectStyles = {
      input: (base) => ({
        ...base,
        '& input': {
          font: 'inherit'
        }
      })
    };

    return (
      <AsyncSelect
        value={this.state.selectedValues}
        isMulti
        loadOptions={this.loadOptions}
        onInputChange={this.handleInputChange}
        onChange={this.handleSelect}
        components={components}
        classes={classes}
        styles={selectStyles}
        textFieldProps={{ label, InputLabelProps: { shrink: true } }}
        noOptionsMessage={() => 'Type to search...'}
      />
    );
  }
}
export default withStyles(styles)(AutocompleteSearch);

import React, { Component } from 'react';
import { Dialog } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Mutation } from '@apollo/client/react/components';
import CreateGroup from './CreateGroup';
import EditGroup from './EditGroup';
import { CreateGroupMutation } from '../mutations/CreateGroup';
import { UpdateGroupMutation } from '../mutations/UpdateGroup';

const styles = (theme) => ({
  root: {
    overflow: 'visible'
  },
  divider: {
    height: theme.spacing(2)
  }
});

class GroupDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      group: {}
    };
  }

  _handleCloseDialog = () => {
    this.setState({ open: false });
    this.props.formCloseHandler();
  };

  _mutation = () => {
    if (this.props.newGroup) {
      return CreateGroupMutation;
    } else {
      return UpdateGroupMutation;
    }
  };

  _onComplete = async (data) => {
    if (this.props.submitHandler) {
      this.props.submitHandler();
    } else {
      window.location = '/';
    }
  };

  componentDidMount() {
    let { group, open } = this.props;
    this.setState({ group, open });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let { group, open } = newProps;
    this.setState({ group, open });
  }

  render() {
    const { classes } = this.props;
    const { group, open } = this.state;
    return (
      <Dialog
        open={open}
        onClose={this._handleCloseDialog}
        TransitionProps={{ onExit: this._handleCloseDialog }}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
        classes={{ paperScrollPaper: classes.root }}
      >
        <Mutation
          mutation={this._mutation()}
          onCompleted={(data) => this._onComplete(data)}
        >
          {(groupMutation, { data, error }) => {
            if (error) return <p>Error.</p>;

            if (this.props.newGroup) {
              return (
                <CreateGroup
                  groupMutation={groupMutation}
                  closeHandler={this._handleCloseDialog}
                />
              );
            }
            return (
              <EditGroup
                groupMutation={groupMutation}
                groupId={group.id}
                closeHandler={this._handleCloseDialog}
              />
            );
          }}
        </Mutation>
      </Dialog>
    );
  }
}
export default withStyles(styles)(GroupDialog);

import { useState } from 'react';

import { isEmpty } from 'lodash';

import { ORDER_BY } from '../constants';

/**
 * Custom hook to manage filtering and sorting of gigs.
 *
 * @returns {Object} An object containing:
 * - appliedFilters {Object}: The currently applied filters.
 * - sortOrder {string}: The current sort order.
 * - sortBy {string}: The current sort by field.
 * - handleFilterChange {Function}: Function to update the applied filters.
 * - handleSortChange {Function}: Function to update the sort order.
 * - handleSortByChange {Function}: Function to update the sort by field.
 */
export const useGigFilterAndSort = () => {
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(ORDER_BY[1].value);

  const removeEmpty = (obj) =>
    Object.fromEntries(
      Object.entries(obj).filter(
        ([_, v]) => v !== undefined && v !== null && v !== ''
      )
    );

  const handleFilterChange = (newFilter) => {
    const newFilters = removeEmpty({ ...appliedFilters, ...newFilter });

    setAppliedFilters(isEmpty(newFilters) ? null : newFilters);
  };

  const handleSortOrderChange = (newSort) => {
    setSortOrder(newSort);
  };

  const handleSortByChange = (newSortBy) => {
    setSortBy(newSortBy);
  };

  return {
    appliedFilters,
    sortOrder,
    sortBy,
    handleFilterChange,
    handleSortOrderChange,
    handleSortByChange
  };
};

export default useGigFilterAndSort;

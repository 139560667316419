import React, { Component } from 'react';
import Linkify from 'react-linkify';
import { useQuery } from '@apollo/client';
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button
} from '@material-ui/core';
import yellow from '@material-ui/core/colors/yellow';

import TimestampTableCell from './TimestampTableCell';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { TextlineConversation } from '../queries/textlineConversation'
import Loader from './Loader';

const styles = (theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  whisper: {
    backgroundColor: yellow[100]
  },
  noHistory: {
    margin: '8px',
    fontStyle: 'italic'
  }
});

const componentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

const useStyles = makeStyles((theme) => createStyles(styles(theme)));

export const TextlineHistory = ({ gig }) => {
  const { data, loading, error } = useQuery(TextlineConversation, {
      variables: { gigId: gig.id },
      nextFetchPolicy: 'network-only'
    });

  const convo = data?.gig?.textlineConversation;
  const theme = useTheme();
  const classes = useStyles(theme);

  const ConvoBody = ({ post }) => {
    let msg = '';
    if (post.whisper) {
      msg += '[Whisper] ';
    }
    if (post.body) {
      msg += post.body;
    }
    for (let attachment of post.attachments) {
      msg += `[Attachment] ${attachment.url}`;
    }
    if (msg !== '') {
      return <Linkify componentDecorator={componentDecorator}>{msg}</Linkify>;
    }
  };

  const InlineButton = ({ convo }) => {
    const buttonText = convo.link ? 'View in Textline' : 'Open Textline';
    const link = convo.link
      ? convo.link
      : 'https://application.textline.com/conversations';
    return (
      <Typography align="right">
        <Button
          size="small"
          variant="outlined"
          color="primary"
          target="_blank"
          rel="noopener noreferrer"
          href={link}
        >
          {buttonText}
        </Button>
      </Typography>
    );
  };

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }
  if (convo?.id && convo?.posts?.length > 0) {
    return (
      <div>
        <Typography align="right">
          <Button
            size="small"
            variant="outlined"
            color="primary"
            target="_blank"
            rel="noopener noreferrer"
            href={convo?.link}
          >
            <InlineButton convo={convo} />
          </Button>
          <div className={classes.noHistory}>
            <Typography>
              {convo.link
                ? `No messages since bid acceptance.`
                : `No message history with this driver.`}
            </Typography>
          </div>
        </Typography>
        <Table className={classes.root}>
          <TableHead>
            <TableRow>
              <TableCell>From</TableCell>
              <TableCell>Message</TableCell>
              <TableCell align="right">Timestamp</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {convo?.posts?.map((post) => {
              if (post?.body || post?.attachments.length > 0) {
                return (
                  <TableRow
                    key={post.id}
                    className={post?.whisper ? classes.whisper : ''}
                  >
                    <TableCell>
                      <Typography noWrap>{post?.author?.name}</Typography>
                      <Typography variant="caption">
                        {post?.author?.type}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <ConvoBody post={post} />
                    </TableCell>
                    <TableCell align="right">
                      <TimestampTableCell timestamp={post.timestamp} />
                    </TableCell>
                  </TableRow>
                );
              } else {
                return null;
              }
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
  return null;
};

import React, { useState } from 'react';
import { snakeCase } from 'lodash';
import { useQuery } from '@apollo/client';
import { SelectListContent } from '../SelectListContents';
import { GetGigEventLabels } from '../../queries/getGigEvents';
import { getGigEventDisplayName } from '../../Helper';

export const GigLastEventFilter = ({ appliedFilters, onClickApply, title }) => {
  const { loading, error, data: eventOptions } = useQuery(GetGigEventLabels);

  const formatOptions = (options) => {
    return options.map((option) => ({
      label: getGigEventDisplayName(option.label),
      value: snakeCase(option.label)
    }));
  };

  const allOptions = formatOptions(eventOptions?.events || []);

  const getValueFromAppliedOptions = (applied) => {
    return allOptions.filter((option) => applied.includes(option.value));
  };

  const options = loading ? [] : error ? [] : allOptions;

  const value = appliedFilters?.lastEvent || [];

  const [currentValue, setCurrentValue] = useState(
    getValueFromAppliedOptions(appliedFilters?.lastEvent || [])
  );

  const handleSelectValueChange = (selectValues) => {
    setCurrentValue(value);
    onClickApply({ eventType: selectValues.map((val) => val.value) });
  };

  return (
    <div>
      <SelectListContent
        title={title}
        value={currentValue}
        options={options}
        onChange={handleSelectValueChange}
        isMulti={true}
        maxWidth={700}
        minWidth={250}
      />
    </div>
  );
};

import React, { Component } from 'react';
import { Query } from '@apollo/client/react/components';
import { Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { GetGigsByFilter } from '../queries/gigsByFilter';
import { GigTable } from './GigTable';
import Loader from './Loader';

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
});

const filterMapping = {
  find_driver: 'Find Driver',
  contact_driver: 'Contact Driver',
  monitor: 'Monitor',
  dont_worry: "Don't Worry"
};

class GigsByFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: null
    };
  }

  componentDidMount() {
    const { match } = this.props;
    if (match && match.params && match.params.filter) {
      this.setState({ filter: match.params.filter });
    }
  }

  _expanded(count) {
    if (count > 50) {
      return false;
    }
    return true;
  }

  render() {
    const { classes } = this.props;
    const { filter } = this.state;
    if (filter) {
      return (
        <Query
          query={GetGigsByFilter}
          variables={{ filter }}
          pollInterval={15000}
          refetch
          fetchPolicy="network-only"
          errorPolicy="ignore"
        >
          {({ loading, error, data }) => {
            if (loading) return <Loader />;
            if (error) return <div>{error}</div>;

            const myBoards = data.myBoards;
            return (
              <Paper square className={classes.root}>
                <Typography variant="h5">{filterMapping[filter]}</Typography>
                {myBoards
                  .sort((a, b) => b.gigs.length - a.gigs.length)
                  .map((board) => {
                    return (
                      <GigTable
                        key={board.id}
                        title={board.name}
                        count={board.gigs.length}
                        filter={filter}
                        boardId={board.id}
                        expanded={this._expanded(board.gigs.length)}
                        showFirstAndLastName={
                          data.myBoards.showFirstAndLastName
                        }
                      />
                    );
                  })}
              </Paper>
            );
          }}
        </Query>
      );
    } else {
      return <Loader />;
    }
  }
}

export default withStyles(styles)(GigsByFilter);

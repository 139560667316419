import { gql } from '@apollo/client';

export const GetEventsForGig = gql`
  query GetEventsForGig($id: ID!) {
    gig(id: $id) {
      id
      events {
        ...GigEventConnectionFragment
      }
    }
  }
`;

export const GetNotesForGig = gql`
  query GetNotesForGig($id: ID!) {
    gig(id: $id) {
      id
      notes {
        ...AdminGigNoteConnectionFragment
      }
      consolidation {
        id
        notes {
          ...ConsolidationNoteConnectionFragment
        }
      }
    }
  }
`;

export const GetMessagesForGig = gql`
  query GetMessagesForGig($id: ID!) {
    gig(id: $id) {
      id
      textlineConversation {
        id
        link
        posts {
          id
          body
          timestamp
          author
          whisper
          attachments {
            id
            url
          }
        }
      }
    }
    messageTemplates {
      id
      title
      preview(gigId: $id)
    }
  }
`;

export const GetGigBasicDetails = gql`
  query GetGigBasicDetails($id: ID!) {
    gig(id: $id) {
      id
      ...GigBasicDetailsFragment
      deliveryLocation {
        id
        ...GigLocationFragment
      }
    }
  }
`;

export const GetGigExtendedDetails = gql`
  query GetGigExtendedDetails($id: ID!) {
    gig(id: $id) {
      id
      ...GigBasicDetailsFragment
      ...GigExtendedDetailsFragment
      deliveryLocation {
        id
        ...GigLocationFragment
      }
    }
  }
`;

export const GetBidsForGig = gql`
  query GetBidsForGig($id: ID!) {
    gig(id: $id) {
      id
      bids {
        nodes {
          id
          ...BidFragment
        }
      }
    }
  }
`;

export const GetMapForGig = gql`
  query GetMapForGig($gigId: ID!) {
    gig(id: $gigId) {
      id
      ...GigMapFragment
    }
  }
`;

import { gql } from '@apollo/client';

export const GetMe = gql`
  query GetMe {
    me {
      id
      name
      email
      allRoles {
        id
        name
      }
      groups {
        id
        name
      }
      allBoards {
        id
        name
      }
    }
  }
`;

import { gql } from '@apollo/client';

const GIG_EVENT_FRAGMENT = gql`
  fragment GigEventFragment on GigEvent {
    eventType
    timestamp
  }
`;

const GIG_EVENT_CONNECTION_FRAGMENT = gql`
  fragment GigEventConnectionFragment on GigEventConnection {
    edges {
      cursor
      node {
        id
        ...GigEventFragment
      }
    }
    nodes {
      id
      ...GigEventFragment
    }
    totalCount
  }
`;

export const GigEventFragments = [
  GIG_EVENT_FRAGMENT,
  GIG_EVENT_CONNECTION_FRAGMENT
];

import React, { Component } from 'react';
import { Query } from '@apollo/client/react/components';
import {
  Paper,
  Typography,
  Divider,
  List,
  ListItem,
  ListSubheader,
  ListItemText
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Loader from './Loader';
import { GetMe } from '../queries/me';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  }
});

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

class Me extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Query query={GetMe}>
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) return <div>{error}</div>;
          const user = data.me;
          return (
            <Paper square className={classes.root}>
              <Typography variant="h5">{user.name}</Typography>
              <Typography variant="body1">{user.email}</Typography>
              <List dense>
                <ListSubheader>Groups</ListSubheader>
                {user.groups.map((group) => (
                  <ListItem key={`group-${group.name}`}>
                    <ListItemText primary={`${group.name}`} />
                  </ListItem>
                ))}
                <Divider />
                <ListSubheader>Roles</ListSubheader>
                {user.allRoles.map((role) => (
                  <ListItem key={`role-${role.name}`}>
                    <ListItemText primary={`${role.name}`} />
                  </ListItem>
                ))}

                <Divider />
                <ListSubheader>Boards</ListSubheader>
                {user.allBoards.map((board) => (
                  <ListItemLink
                    href={`/boards/${board.id}`}
                    key={`board-${board.id}`}
                  >
                    <ListItemText primary={`${board.name}`} />
                  </ListItemLink>
                ))}
              </List>
            </Paper>
          );
        }}
      </Query>
    );
  }
}
export default withStyles(styles)(Me);

import { mergeWith } from 'lodash';
import { makeVar, gql } from '@apollo/client';
import { getGigEventDisplayName } from '../Helper';

// Slicing is necessary because the existing data is
// immutable, and frozen in development.
const mergeArrayForPagination = (existing, incoming, offset) => {
  const merged = existing ? existing.slice(0) : [];
  for (let i = 0; i < incoming.length; ++i) {
    merged[offset + i] = incoming[i];
  }
  return merged;
};

const deepMergeObjects = (existing, incoming, ignoreNull = true) => {
  return mergeWith(existing, incoming, (objValue, srcValue) => {
    if (ignoreNull && srcValue === null) {
      return objValue;
    }
    return undefined;
  });
};

// Use when incoming data will have ALL currently relevent items in the collection.
// This will merge matching items in the existing array with the incoming array, overwriting existing data with incoming data where it already exists.
// Example:
// existing = [{ id: 1, name: 'foo' }, { id: 2, name: 'baz', description: 'bar', title: 'foo' }]
// incoming = [{ id: 1, name: 'baz', description: 'bin', count: 3 }]
// mergeArraysById(existing, incoming) => [{ id: 2, name: 'baz', description: 'bin', title: 'foo', count: 3 }]
const mergeDataInIncomingArrayMembers = (
  existing,
  incoming,
  readField,
  mergeObjects
) => {
  const newArr = incoming.map((inItem) => {
    const existingItem = existing.find(
      (exItem) => readField('id', exItem) === readField('id', inItem)
    );
    return existingItem ? mergeObjects(existingItem, inItem) : inItem;
  });
  return newArr;
};

export const loadedGigsCountByBucket = makeVar({});

export const getLoadedGigsCountByBucket = (bucketId) => {
  return loadedGigsCountByBucket()[bucketId];
};

export const updateLoadedGigsCountByBucket = (bucketId, count) => {
  const current = loadedGigsCountByBucket();
  loadedGigsCountByBucket({ ...current, [bucketId]: count });
};

export const ClientSideSchema = gql`
  extend type Bucket {
    loadedGigsCount: Int
  }

  extend type Gig {
    bucketId: ID
  }
`;

export const ApolloTypePolicies = {
  Board: {
    merge: true,
    fields: {
      buckets: {
        merge(existing = [], incoming, { readField, mergeObjects }) {
          return mergeDataInIncomingArrayMembers(
            existing,
            incoming,
            readField,
            mergeObjects
          );
        }
      },
      bucketsByPriority: {
        merge(existing = [], incoming, { readField, mergeObjects }) {
          return mergeDataInIncomingArrayMembers(
            existing,
            incoming,
            readField,
            mergeObjects
          );
        }
      }
    }
  },
  Bucket: {
    merge: true
  },
  Gig: {
    keyArgs: (args) => {
      return args.id;
    },
    merge(existing, incoming, { mergeObjects }) {
      return mergeObjects(existing, incoming);
    },
    fields: {
      consolidation: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
        fields: {
          gigs: {
            keyFields: (args, context) => {
              return context.readField('id', args);
            },
            merge(existing = [], incoming, { readField, mergeObjects }) {
              return mergeDataInIncomingArrayMembers(
                existing,
                incoming,
                readField,
                mergeObjects
              );
            }
          }
        }
      }
    }
  },
  GigConnection: {
    keyArgs: (args) => {
      return args.id;
    },
    merge(existing, incoming, { mergeObjects }) {
      return mergeObjects(existing, incoming);
    },
    fields: {
      nodes: {
        merge(existing = [], incoming, { args: { offset }, mergeObjects }) {
          return mergeArrayForPagination(existing, incoming, offset);
        }
      }
    }
  },
  GigEvent: {
    merge(existing, incoming, { mergeObjects }) {
      return mergeObjects(existing, incoming);
    },
    fields: {
      eventType: {
        read(existing) {
          return getGigEventDisplayName(existing);
        }
      }
    }
  },
  Profile: {
    keyFields: ['id'],
    merge: true
  },
  Query: {
    fields: {
      myBoards: {
        merge(existing = [], incoming, { readField, mergeObjects }) {
          return mergeDataInIncomingArrayMembers(
            existing,
            incoming,
            readField,
            mergeObjects
          );
        }
      }
    }
  }
};

import React, { Component } from 'react';
import { Query } from '@apollo/client/react/components';

import Loader from './Loader';
import UserForm from './UserForm';

import { GetUserForEdit } from '../queries/getUserForEdit';

class EditUser extends Component {
  state = {
    userId: null,
    userMutation: null
  };

  componentDidMount() {
    let { userId, userMutation } = this.props;
    this.setState({ userId, userMutation });
  }

  render() {
    const { closeHandler } = this.props;
    const { userId, userMutation } = this.state;
    return (
      <Query query={GetUserForEdit} variables={{ id: userId }}>
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) return <div>{error}</div>;

          if (data) {
            return (
              <UserForm
                userMutation={userMutation}
                user={data.user}
                userId={userId}
                closeHandler={closeHandler}
              />
            );
          }
        }}
      </Query>
    );
  }
}

export default EditUser;

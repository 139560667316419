import { gql } from '@apollo/client';

export const CreateUserMutation = gql`
  mutation CreateUserMutation(
    $name: String
    $email: String
    $password: String
    $groupIds: [ID!]
    $roleIds: [ID!]
    $boardIds: [ID!]
  ) {
    createUser(
      user: {
        name: $name
        email: $email
        password: $password
        groupIds: $groupIds
        roleIds: $roleIds
        boardIds: $boardIds
      }
    ) {
      user {
        id
        email
        name
      }
    }
  }
`;

import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Mutation } from '@apollo/client/react/components';
import { DeleteBoardMutation } from '../mutations/DeleteBoard';
import red from '@material-ui/core/colors/red';

const styles = (theme) => ({
  delete: {
    color: red[500]
  }
});

class DeleteBoardButton extends Component {
  state = {
    boardId: null
  };

  _onComplete = (async) => {
    this.props.refresh();
  };

  componentDidMount() {
    let { boardId } = this.props;
    this.setState({ boardId });
  }

  render() {
    const { classes } = this.props;
    const { boardId } = this.state;
    return (
      <Mutation
        mutation={DeleteBoardMutation}
        variables={{ id: boardId }}
        onCompleted={this._onComplete}
      >
        {(deleteMutation, { _, loading }) => (
          <Button
            onClick={() => window.confirm('Are you sure?') && deleteMutation()}
            className={classes.delete}
            disabled={loading}
          >
            Delete
          </Button>
        )}
      </Mutation>
    );
  }
}
export default withStyles(styles)(DeleteBoardButton);

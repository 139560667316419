import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { PulseLoader } from 'react-spinners';

const styles = (theme) => ({
  loader: {
    minHeight: '100pt',
    backgroundColor: '#FFF',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    width: '100%'
  }
});

const Loader = (props) => {
  const { classes, loading } = props;
  return (
    <div className={classes.loader}>
      <PulseLoader height={8} width={500} color={'#004F50'} loading={loading} />
    </div>
  );
};

export default withStyles(styles)(Loader);

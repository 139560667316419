import React, { Component } from 'react'
import Moment from 'react-moment';
import moment from 'moment-timezone';
import { Typography } from '@material-ui/core'

class TimestampTableCell extends Component {
  render() {
    const { timestamp } = this.props;

    const timeZoneAbbreviation = moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z');
    
    return (
      <span>
        <Typography>
          <Moment style={{ display: 'inline' }} date={timestamp} format="h:mma"></Moment>
          <p style={{ display: 'inline', marginLeft: '4px' }} >({ timeZoneAbbreviation })</p>
        </Typography>
        <Typography variant="caption">
          <Moment format="M/D">{ timestamp }</Moment>
        </Typography>
      </span>
    )
  }
}

export default TimestampTableCell

import { gql } from '@apollo/client';

const GIG_LOCATION_FRAGMENT = gql`
  fragment GigLocationFragment on GigLocation {
    timezone
    city
    latitude
    longitude
    state
    storeNumber
    zip
  }
`;

export const GigLocationFragments = [GIG_LOCATION_FRAGMENT];

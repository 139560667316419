import { gql } from '@apollo/client';

export const SearchMasterAccounts = gql`
  query SearchMasterAccounts($search: String!) {
    id
    allMasterAccounts(search: $search) {
      id
      name
    }
  }
`;

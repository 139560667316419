import { gql } from '@apollo/client';

export const SearchUsers = gql`
  query SearchUsers($search: String!) {
    id
    allUsers(search: $search) {
      id
      name
      email
      groups {
        id
        name
      }
      roles {
        id
        name
      }
      boards {
        id
        name
      }
    }
  }
`;

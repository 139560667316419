import React, { Component } from 'react';
import { Typography, Link } from '@material-ui/core';
import { Map, Marker, Polyline, GoogleApiWrapper } from 'google-maps-react';
import { withStyles } from '@material-ui/core/styles';
import {
  GOOGLE_API_KEY,
  GOOGLE_MAPS_PICKUP_ICON,
  GOOGLE_MAPS_DELIVERY_ICON,
  GOOGLE_MAPS_DRIVER_ICON,
  GOOGLE_MAPS_PACKAGE_ICON,
  GOOGLE_MAPS_PICKUP_ICON_SECONDARY,
  GOOGLE_MAPS_DELIVERY_ICON_SECONDARY,
  GOOGLE_MAPS_PACKAGE_ICON_SECONDARY
} from '../constants';

import Moment from 'react-moment';
import moment from 'moment-timezone';
import decodePolyline from 'decode-google-map-polyline';

const styles = (theme) => ({
  root: {
    minHeight: 500,
    display: 'block'
  },
  container: {
    width: '100%',
    position: 'relative'
  }
});

class RouteMap extends Component {
  state = {
    gig: null,
    map: null,
    driverLastPosition: null,
    updatedBounds: null
  };

  refreshMap(mapProps, map) {
    let bounds = mapProps.bounds;
    map.fitBounds(bounds);
    map.panToBounds(bounds);
  }

  _whileChangeMap(mapProps) {
    this.setState({
      updatedBounds: mapProps.bounds
    });
  }

  componentDidMount() {
    const { google, gig } = this.props;
    this.setState({ google, gig });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let { gig } = newProps;
    if (gig !== this.state.gig) {
      this.setState({ gig });
    }
  }

  render() {
    const { classes, google } = this.props;
    const { gig, updatedBounds } = this.state;

    if (gig) {
      const {
        consolidationDeliveryCount = 0,
        pickupLocation = null,
        deliveryLocation = null,
        routeData = null,
        driverLastPosition = null,
        driverPolyline = null,
        distance = null,
        driver = null
      } = gig;

      const {
        id: consolidationId = null,
        routePolyline: consolidationRoutePolyline = null,
        consolidationGigs = []
      } = gig?.consolidation || {};

      let bounds = new google.maps.LatLngBounds();
      let iconProps = {
        size: new google.maps.Size(80, 80),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(20, 20),
        scaledSize: new google.maps.Size(40, 40)
      };

      let markers = [];
      // handle consolidations
      if (consolidationDeliveryCount > 1) {
        
        for (let [index, routeGig] of consolidationGigs.entries()) {
          if (routeGig.id !== gig.id) {
            let package_icon = routeGig.state === "delivered"
              ? GOOGLE_MAPS_PACKAGE_ICON_SECONDARY
              : GOOGLE_MAPS_PACKAGE_ICON
            let stopPos = {
              lat: routeGig.deliveryLocation.latitude,
              lng: routeGig.deliveryLocation.longitude
            };
            let markerIcon = { url: package_icon, ...iconProps };
            let marker = (
              <Marker
                key={`stop-${routeGig.id}`}
                name={`stop-${routeGig.id}`}
                title={`${routeGig.title} - ${index + 1} of ${gig.consolidationDeliveryCount}`}
                position={stopPos}
                icon={markerIcon}
              />
            );
            markers.push(marker);
          }
        }
      }

      let routePolyline;
      if (consolidationRoutePolyline)
        routePolyline = (
          <Polyline
            path={decodePolyline(consolidationRoutePolyline)}
            strokeColor="#004F50"
            strokeOpacity={0.8}
            strokeWeight={10}
          />
        );
      else if (routeData && routeData?.polyline) {
        routePolyline = (
          <Polyline
            path={decodePolyline(routeData.polyline)}
            strokeColor="#004F50"
            strokeOpacity={0.8}
            strokeWeight={10}
          />
        );
      }

      // pickup marker
      if (
        pickupLocation &&
        pickupLocation?.latitude &&
        pickupLocation?.longitude
      ) {
        let pickupPos = {
          lat: pickupLocation?.latitude,
          lng: pickupLocation?.longitude
        };
        bounds.extend(pickupPos);
        let pickupIcon = { url: GOOGLE_MAPS_PICKUP_ICON, ...iconProps };
        let pickup = (
          <Marker
            key="pickup"
            name="pickup"
            title="Pickup"
            position={pickupPos}
            icon={pickupIcon}
          />
        );
        markers.push(pickup);
      }

      // delivery marker

      if (
        deliveryLocation &&
        deliveryLocation?.latitude &&
        deliveryLocation?.longitude
      ) {
        let deliveryPos = {
          lat: deliveryLocation?.latitude,
          lng: deliveryLocation?.longitude
        };
        bounds.extend(deliveryPos);
        let deliveryIcon = { url: GOOGLE_MAPS_DELIVERY_ICON, ...iconProps };
        let delivery = (
          <Marker
            key="delivery"
            name="delivery"
            title="Delivery"
            position={deliveryPos}
            icon={deliveryIcon}
          />
        );
        markers.push(delivery);
      }

      // driver position marker
      if (
        driverLastPosition &&
        driverLastPosition.latitude &&
        driverLastPosition.longitude
      ) {
        let driverPos = {
          lat: driverLastPosition.latitude,
          lng: driverLastPosition.longitude
        };
        bounds.extend(driverPos);
        let driverIcon = { url: GOOGLE_MAPS_DRIVER_ICON, ...iconProps };
        let driver = (
          <Marker
            key="driver"
            name="driver"
            title="Driver"
            position={driverPos}
            icon={driverIcon}
          />
        );
        markers.push(driver);
      }

      let drivenPolyline;
      if (driverPolyline) {
        drivenPolyline = (
          <Polyline
            path={decodePolyline(driverPolyline)}
            strokeColor="#a5d6a7"
            strokeWeight={8}
          />
        );
      }

      // extract consolidation data from driver variable
      let consolidationPolylines = [];
      let consolidationLinks = [];
      if (driver) {
        let consolidationLink;
        let { assignedConsolidations } = driver;
        assignedConsolidations.forEach((driverConsolidation) => {
          if (driverConsolidation.id !== consolidationId) {
            consolidationLink = (
              <Link
                key={driverConsolidation.id}
                style={{ color: '#00BBBB', paddingLeft: 8 }}
                target="_blank"
                href={`https://admin.roadie.com/consolidations/${driverConsolidation.id}`}
              >
                {consolidationLinks.length + 1}
              </Link>
            );
            let consolidationPolyline = (
              <Polyline
                path={decodePolyline(driverConsolidation.routePolyline)}
                strokeColor="#5680DF"
                strokeWeight={8}
                key={driverConsolidation.id}
              />
            );
            consolidationPolylines.push(consolidationPolyline);
            consolidationGigs.forEach(
              ({ id, pickupLocation, deliveryLocation }, index) => {
                let pickupPos = {
                  lat: pickupLocation.latitude,
                  lng: pickupLocation.longitude
                };
                let pickupIcon = {
                  url: GOOGLE_MAPS_PICKUP_ICON_SECONDARY,
                  ...iconProps
                };
                let pickupMarker = (
                  <Marker
                    key={`cg_pickup_${id}-${index}`}
                    name="cg_pickup"
                    title="ConsolidationPickup"
                    position={pickupPos}
                    icon={pickupIcon}
                  />
                );
                markers.push(pickupMarker);

                let deliveryPos = {
                  lat: deliveryLocation.latitude,
                  lng: deliveryLocation.longitude
                };
                let deliveryIcon = {
                  url: GOOGLE_MAPS_DELIVERY_ICON_SECONDARY,
                  ...iconProps
                };
                let deliveryMarker = (
                  <Marker
                    key={`cg_delivery_${id}-${index}`}
                    name="cg_delivery"
                    title="ConsolidationDelivery"
                    position={deliveryPos}
                    icon={deliveryIcon}
                  />
                );
                markers.push(deliveryMarker);
              }
            );
          } else {
            consolidationLink = (
              <Link
                key={driverConsolidation.id}
                style={{ color: '#008383', marginLeft: 8 }}
                target="_blank"
                href={`https://admin.roadie.com/consolidations/${driverConsolidation.id}`}
              >
                {consolidationLinks.length + 1}
              </Link>
            );
          }
          consolidationLinks.push(consolidationLink);
        });
      }

      let map = (
        <Map
          draggable
          google={google}
          zoom={12}
          style={{ minHeight: '500px', height: '100%', position: 'relative' }}
          bounds={updatedBounds ? updatedBounds : bounds}
          onReady={this.refreshMap}
          onBoundsChanged={(mapProps) => this._whileChangeMap(mapProps)}
        >
          {markers}
          {consolidationPolylines}
          {routePolyline}
          {drivenPolyline}
        </Map>
      );

      const timeZoneAbbreviation = moment()
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format('z');

      let lastPositionTimestamp = null;
      let lastPing = null;
      let batchesLink = null;
      if (driverLastPosition) {
        lastPing = (
          <span>
            <Typography
              align="left"
              component="body2"
              style={{ fontSize: 17, fontWeight: 500 }}
            >
              Driver's Last Ping{' '}
            </Typography>
            <Typography
              variant="body2"
              component="body2"
              style={{
                color: '#7A7A7A',
                fontSize: 15,
                fontWeight: 500,
                marginLeft: 4
              }}
            >
              (<Moment format="hh:mma">{driverLastPosition.timestamp}</Moment>{' '}
              {timeZoneAbbreviation} •{' '}
              <Moment fromNow>{driverLastPosition.timestamp}</Moment>)
            </Typography>
          </span>
        );
      }

      batchesLink = (
        <span>
          <Typography
            align="left"
            component="body2"
            style={{ fontSize: 17, fontWeight: 500 }}
          >
            View Batches {consolidationLinks}
          </Typography>
        </span>
      );

      lastPositionTimestamp = (
        <div>
          <div style={{ paddingTop: 24 }}>{lastPing}</div>
          <div style={{ paddingTop: 15 }}>{batchesLink}</div>
        </div>
      );
      return (
        <div>
          <div className={classes.root}>
            <div className={classes.container}>{map}</div>
          </div>
          {lastPositionTimestamp}
        </div>
      );
    }
    return <div></div>;
  }
}

export default GoogleApiWrapper({ apiKey: GOOGLE_API_KEY })(
  withStyles(styles)(RouteMap)
);

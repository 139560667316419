import React, { Component } from 'react';
import { TextField, Button , DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ApolloConsumer } from '@apollo/client';
import AutocompleteSearch from './AutocompleteSearch';

const styles = (theme) => ({
  root: {
    overflow: 'visible'
  },
  divider: {
    height: theme.spacing(2)
  },
  autocomplete: {
    height: 'auto'
  }
});

class GroupForm extends Component {
  _onChange = (event) => {
    let value = event.target.value;
    let key = event.target.id;
    this.setState({ [key]: value });
  };

  _userChange = (value, action) => {
    this.setState({ user_ids: value.map((v) => v.value) });
  };

  _roleChange = (value, action) => {
    this.setState({ role_ids: value.map((v) => v.value) });
  };

  _boardChange = (value, action) => {
    this.setState({ board_ids: value.map((v) => v.value) });
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.props.groupMutation({
      variables: {
        id: this.props.groupId,
        name: this.state.name,
        roleIds: this.state.role_ids,
        userIds: this.state.user_ids,
        boardIds: this.state.board_ids
      }
    });
  };

  render() {
    const { classes, closeHandler, group } = this.props;

    return (
      <form onSubmit={this.onSubmit}>
        <DialogTitle id="form-dialog-title">
          Edit Group: {group.name}
        </DialogTitle>
        <DialogContent className={classes.root}>
          <div>
            <TextField
              required
              id="name"
              label="Name"
              className={classes.textField}
              margin="normal"
              fullWidth
              autoFocus
              onChange={this._onChange}
              defaultValue={group.name}
            />
            <div className={classes.divider} />
            <ApolloConsumer>
              {(client) => (
                <div>
                  <AutocompleteSearch
                    id="role_ids"
                    label="Roles"
                    value={group.roles}
                    client={client}
                    modelType="Role"
                    onChange={this._roleChange}
                    className={classes.autocomplete}
                  />
                  <div className={classes.divider} />
                  <AutocompleteSearch
                    id="user_ids"
                    label="Users"
                    value={group.users}
                    client={client}
                    modelType="User"
                    onChange={this._userChange}
                    className={classes.autocomplete}
                  />
                  <div className={classes.divider} />
                  <AutocompleteSearch
                    id="board_ids"
                    label="Boards"
                    value={group.boards}
                    client={client}
                    modelType="Board"
                    onChange={this._boardChange}
                    className={classes.autocomplete}
                  />
                </div>
              )}
            </ApolloConsumer>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" type="submit">
            Submit
          </Button>
          <Button variant="outlined" onClick={closeHandler}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    );
  }
}
export default withStyles(styles)(GroupForm);

import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Loader from './Loader';
import BoardForm from './BoardForm';
import { GetBoardForEditing } from '../queries/getBoardForEditing';

const EditBoard = ({ boardId, boardMutation, closeHandler }) => {
  const shouldSkip = !boardId;

  const { loading, error, data } = useQuery(GetBoardForEditing, {
    variables: { id: boardId },
    skip: shouldSkip, // Skip the query if `boardId` is not yet set
  });

  if (loading) return <Loader />;
  if (error) return <div>{error.message}</div>;

  if (data) {
    return (
      <BoardForm
        boardMutation={boardMutation}
        board={data.board}
        boardId={boardId}
        closeHandler={closeHandler}
        checked={data.board.showFirstAndLastName}
        textlineGroupUuid={data.board.textlineGroupUuid}
      />
    );
  }

  return null;
};

export default EditBoard;

import React, { useState } from 'react';
import Linkify from 'react-linkify';
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tabs,
  Tab,
  TableFooter,
  Button,
  Hidden,
  Grid,
  useTheme
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

import TimestampTableCell from './TimestampTableCell';
import Loader from './Loader';
const styles = (theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  }
});

const useStyles = makeStyles((theme) => createStyles(styles(theme)));

export const NoteListing = ({ loading, error, data }) => {
  const [tab, setTab] = useState('gig');
  const theme = useTheme();

  const classes = useStyles(theme);

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <div>{error}</div>;
  }

  if (data) {
    const gigNotes = data?.gig?.notes?.nodes ?? [];
    const consolidationNotes = data?.gig?.consolidation?.notes?.nodes ?? [];
    const notes = tab === 'gig' ? gigNotes : consolidationNotes;
    const createNoteUrl =
      tab === 'gig'
        ? `https://admin.roadie.com/gigs/${data?.gig.id}/notes/new`
        : `https://admin.roadie.com/consolidations/${data?.gig?.consolidation?.id}/notes/new`;

    return (
      <>
        <Tabs
          value={tab}
          onChange={(_, tab) => setTab(tab)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
        >
          <Tab label={`Gig Notes (${gigNotes.length ?? 0})`} value="gig" />
          <Tab
            label={`Consolidation Notes (${consolidationNotes?.length ?? 0})`}
            value="consolidation"
          />
        </Tabs>
        <Table className={classes.root}>
          <TableHead>
            <Hidden smDown>
              <TableRow>
                <TableCell>Support Rep</TableCell>
                <TableCell>Message</TableCell>
                <TableCell align="right">Timestamp</TableCell>
              </TableRow>
            </Hidden>
          </TableHead>
          <TableBody>
            {notes.map((note) => {
              return (
                <TableRow key={note?.id}>
                  <Hidden xsDown>
                    <TableCell>
                      {note?.adminUser?.name ?? '[Automated]'}
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        <Linkify properties={{ target: '_blank' }}>
                          {note?.message}
                        </Linkify>
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <TimestampTableCell timestamp={note?.timestamp} />
                    </TableCell>
                  </Hidden>

                  <Hidden smUp>
                    <TableCell>
                      <Grid container>
                        <Grid item xs={6}>
                          {note?.adminUser ?? '[Automated]'}
                        </Grid>
                        <Grid item xs={6} align="right">
                          <TimestampTableCell timestamp={note?.timestamp} />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="caption">
                            <Linkify properties={{ target: '_blank' }}>
                              {note?.message}
                            </Linkify>
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </Hidden>
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell align="right" colSpan="3">
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  target="_blank"
                  href={createNoteUrl}
                >
                  Create {tab === 'gig' ? 'Gig' : 'Consolidation'} Note
                </Button>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </>
    );
  }
};

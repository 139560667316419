import React, { Component } from 'react';
import { Dialog } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Mutation } from '@apollo/client/react/components';
import CreateUser from './CreateUser';
import EditUser from './EditUser';
import { CreateUserMutation } from '../mutations/CreateUser';
import { UpdateUserMutation } from '../mutations/UpdateUser';

const styles = (theme) => ({
  divider: {
    height: theme.spacing(2)
  }
});

class UserDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      user: {}
    };
  }

  _handleCloseDialog = () => {
    this.setState({ open: false });
    this.props.formCloseHandler();
  };

  _mutation = () => {
    if (this.props.newUser) {
      return CreateUserMutation;
    } else {
      return UpdateUserMutation;
    }
  };

  _onComplete = async (data) => {
    if (this.props.submitHandler) {
      this.props.submitHandler();
    } else {
      window.location = '/';
    }
  };

  componentDidMount() {
    let { user, open } = this.props;
    this.setState({ user, open });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let { user, open } = newProps;
    this.setState({ user, open });
  }

  render() {
    const { user, open } = this.state;
    return (
      <Dialog
        open={open}
        onClose={this._handleCloseDialog}
        TransitionProps={{ onExit: this._handleCloseDialog }}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
        scroll="paper"
      >
        <Mutation
          mutation={this._mutation()}
          onCompleted={(data) => this._onComplete(data)}
        >
          {(userMutation, { data, error }) => {
            if (error) return <p>Error.</p>;

            if (this.props.newUser) {
              return (
                <CreateUser
                  userMutation={userMutation}
                  closeHandler={this._handleCloseDialog}
                />
              );
            }
            return (
              <EditUser
                userMutation={userMutation}
                userId={user.id}
                closeHandler={this._handleCloseDialog}
              />
            );
          }}
        </Mutation>
      </Dialog>
    );
  }
}
export default withStyles(styles)(UserDialog);

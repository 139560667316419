import React, { memo } from 'react';
import { Fade, AccordionDetails } from '@material-ui/core';
import { GigTableRow } from './GigTableRow';
import { useBoardContext } from '../hooks/useBoardContext';

export const GigTable = memo(
  ({
    classes,
    gigs,
    boardId,
    showFirstAndLastName,
    textlineGroupUuid,
    children,
    bucketId
  }) => {
    const { setGigExpandedState, bucketsExpandedState } = useBoardContext();
    const isGigExpanded = (gigId) => {
      return bucketsExpandedState[bucketId]?.gigs?.[gigId] || false;
    };
    return (
      <AccordionDetails className={classes.panelDetails}>
        {gigs?.length > 0 && (
          <Fade in>
            <div className={classes.gigRow}>
              {gigs.map((gig) => (
                <GigTableRow
                  gig={gig}
                  key={gig.id}
                  boardId={boardId}
                  showFirstAndLastName={showFirstAndLastName}
                  textlineGroupUuid={textlineGroupUuid}
                  bucketId={bucketId}
                  isGigExpanded={isGigExpanded(gig.id)}
                  setGigExpandedState={(_, isExpanded) =>
                    setGigExpandedState(gig.id, bucketId, isExpanded)
                  }
                />
              ))}
              {children}
            </div>
          </Fade>
        )}
      </AccordionDetails>
    );
  }
);

import React, { useState, useRef, useMemo, Fragment } from 'react';

import { ErrorBoundary } from '@sentry/react';

import { isExternal } from '../auth';
import { formatDate, isPast, isToday, differenceInMinutes } from 'date-fns';
import { tz, TZDate } from '@date-fns/tz';

import {
  Chip,
  Fade,
  Grid,
  Button,
  Typography,
  Hidden,
  Tooltip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  useTheme
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { BATCH_ICON, DELIVERY_ICON } from '../constants';
import Moment from 'react-moment';
import Countdown from 'react-countdown-now';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { GigDetails } from './GigDetails';
import LastEvent from './LastEvent';
import { DynamicEta } from './DynamicEta';
import { LightToolTip } from './CustomToolTip';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getShortTimezoneName } from '../Helper';

const useStyles = makeStyles((theme) =>
  createStyles({
    titlePadding: {
      paddingLeft: 32,
      paddingRight: 32
    },
    adminLink: {
      fontSize: 17,
      color: '#008383',
      textTransform: 'uppercase'
    },
    columnText: {
      fontSize: 17,
      letterSpacing: 0.08
    }
  })
);

const countdownTimer = ({ total, hours, minutes, completed }) => {
  if (completed) return;
  if (hours > 0) {
    return (
      <span>
        {hours}h {minutes}m
      </span>
    );
  }
  return <span>{minutes}m</span>;
};

const GigId = ({
  gigId,
  consolidationPosition,
  consolidationDeliveryCount,
  assignedConsolidationsOrder,
  assignedConsolidationsCount,
  totalValue = 0,
  adminLink
}) => {
  let batchesCount;
  if (assignedConsolidationsCount) {
    batchesCount = (
      <Typography
        variant="body2"
        component="div"
        style={{
          fontSize: 17,
          fontWeight: 400,
          letterSpacing: 0.25
        }}
      >
        <img
          src={BATCH_ICON}
          alt="Package Stack Icon"
          height="16px"
          style={{ verticalAlign: 'text-top', marginRight: 5 }}
        />
        {assignedConsolidationsOrder || '-'} of{' '}
        {assignedConsolidationsCount || 0} batches
      </Typography>
    );
  }
  const [copied, setCopied] = useState(false);
  if (isExternal()) {
    return <Typography variant="body2">Gig ID: #{gigId}</Typography>;
  } else {
    return (
      <Typography variant="body2" component="span">
        <Tooltip title={copied ? 'Copied ID!' : 'Copy ID'}>
          <CopyToClipboard text={adminLink} onCopy={() => setCopied(true)}>
            <Button
              color="primary"
              style={{ color: '#7A7A7A' }}
              size="small"
              onClick={(event) => event.stopPropagation()}
            >
              ID: {gigId} &#160;{' '}
              <FileCopyIcon fontSize="inherit" style={{ color: '#008383' }} />
            </Button>
          </CopyToClipboard>
        </Tooltip>
        <Typography
          variant="body2"
          component="div"
          style={{
            fontSize: 17,
            fontWeight: 400,
            letterSpacing: 0.25,
            marginBottom: 5
          }}
        >
          <img
            src={DELIVERY_ICON}
            alt="Package Icon"
            height="16px"
            style={{ verticalAlign: 'text-top', marginRight: 5 }}
          />
          {consolidationPosition} of {consolidationDeliveryCount} deliveries ($
          {totalValue})
        </Typography>
        {batchesCount}
      </Typography>
    );
  }
};

const Deadline = ({ deliveryDeadline, deliveryLocation }) => {
  const now = useRef(new Date());

  const formatMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return minutes > 60
      ? `${hours}h ${remainingMinutes}m`
      : `${remainingMinutes}m`;
  };

  const deadline = new Date(deliveryDeadline);
  const isLate = isPast(deadline);
  const timeUntilDeadline = differenceInMinutes(deadline, now.current);
  const deadlineInDeliveryTimezone = (
    <Typography
      variant="body2"
      style={{ fontSize: 15, letterSpacing: 0.4, color: '#7A7A7A' }}
    >
      (
      <Moment format={'h:mma z'} tz={deliveryLocation.timezone}>
        {deadline}
      </Moment>
      )
    </Typography>
  );
  const deadlineInEasternTimezone = () => {
    const rawDate = new TZDate(deadline, 'America/New_York');
    const shortTimezone = getShortTimezoneName(rawDate);
    return `${formatDate(deadline, 'h:mmaaa', { in: tz('America/New_York') })} ${shortTimezone}`;
  };

  let countdown;
  if (isLate) {
    let chipLabel = (
      <Typography variant="body2" style={{ fontSize: 17, letterSpacing: 0.08 }}>
        <Moment format="MMM DD">{deadline}</Moment> •{' '}
        <Moment fromNow>{deadline}</Moment>
      </Typography>
    );
    countdown = (
      <Chip
        label={chipLabel}
        component="span"
        style={{
          backgroundColor: '#F9D9D3',
          color: '#9F2D1A',
          fontSize: 17,
          fontWeight: 500,
          height: 24,
          borderRadius: 8,
          marginTop: 7
        }}
      />
    );
  } else {
    let chipLabel = (
      <Typography variant="body2" style={{ fontSize: 17, letterSpacing: 0.08 }}>
        {formatMinutes(timeUntilDeadline)}
      </Typography>
    );
    countdown = (
      <Chip
        color="primary"
        label={chipLabel}
        component="span"
        style={{
          backgroundColor: '#E3F2DA',
          color: '#508630',
          fontSize: 15,
          fontWeight: 500,
          height: 24,
          borderRadius: 8
        }}
      />
    );
  }
  const isSameTimezone = deliveryLocation.timezone === 'America/New_York';

  return (
    <div>
      <Typography variant="body1" style={{ fontSize: 17, letterSpacing: 0.08 }}>
        {deadlineInEasternTimezone()}
      </Typography>
      {!isSameTimezone && (
        <Typography
          variant="body2"
          style={{
            fontSize: 15,
            letterSpacing: 0.4,
            color: '#7A7A7A'
          }}
        >
          {deadlineInDeliveryTimezone}
        </Typography>
      )}
      {countdown && <Typography variant="body2">{countdown}</Typography>}
    </div>
  );
};

const DriverExpectation = ({ driverExpectation, deliveryLocation }) => {
  if (driverExpectation) {
    let today = new Date();
    let driverExpectationCountdown;
    let driverExpectationFromNow;
    let driverExpectationDate = new Date(driverExpectation);
    let localTime = (
      <Moment format={'hh:mma z'} tz={deliveryLocation.timezone}>
        {driverExpectationDate}
      </Moment>
    );

    if (today < driverExpectation) {
      driverExpectationCountdown = (
        <Countdown date={driverExpectationDate} renderer={countdownTimer} />
      );
    } else {
      driverExpectationFromNow = (
        <Moment fromNow>{driverExpectationDate}</Moment>
      );
    }

    let title = (
      <Fragment>
        <Typography
          variant="body2"
          style={{ fontSize: 15, letterSpacing: 0.4, color: '#7A7A7A' }}
        >
          ({localTime} •{' '}
          {driverExpectationCountdown || driverExpectationFromNow})
        </Typography>
      </Fragment>
    );

    return (
      <LightToolTip title={title} arrow>
        <Moment format={'hh:mma z'} tz="America/New_York">
          {driverExpectation}
        </Moment>
      </LightToolTip>
    );
  }
};

const Driver = ({ driver }) => {
  const { name, valueOfCurrentlyAcceptedGigs } = driver || {};
  return (
    <Typography variant="body1" style={{ fontSize: 17, letterSpacing: 0.08 }}>
      {!!name && name}&nbsp;
      {valueOfCurrentlyAcceptedGigs
        ? `($${valueOfCurrentlyAcceptedGigs})`
        : '(N/A)'}
    </Typography>
  );
};

export const GigTableRow = ({
  gig,
  boardId,
  showFirstAndLastName,
  textlineGroupUuid,
  isGigExpanded,
  setGigExpandedState
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const {
    title,
    distance,
    driver,
    lastEvent,
    declaredValue,
    consolidationPosition,
    consolidationDeliveryCount,
    assignedConsolidationsOrder,
    deliveryDeadline,
    deliveryLocation,
    consolidation,
    driverExpectation,
    dynamicEtaTimestamp,
    dynamicEtaDistanceRemaining
  } = gig;

  const adminLink = `https://admin.roadie.com/gigs/${gig.id}`;

  return (
    <Accordion
      defaultExpanded={false}
      isGigExpanded={isGigExpanded}
      square
      elevation={1}
      onChange={setGigExpandedState}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={`${classes.titlePadding}`}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="top"
          wrap="nowrap"
        >
          <Grid item md={3} xs={8}>
            <Typography variant="body2">
              {isExternal() ? (
                title
              ) : (
                <Link
                  className={`${classes.adminLink}`}
                  target="_blank"
                  href={adminLink}
                >
                  {title}
                </Link>
              )}
            </Typography>
            <GigId
              gigId={gig.id}
              adminLink={adminLink}
              consolidationDeliveryCount={consolidationDeliveryCount}
              consolidationPosition={consolidationPosition}
              assignedConsolidationsCount={driver?.assignedConsolidationsCount}
              assignedConsolidationsOrder={assignedConsolidationsOrder}
              totalValue={consolidation?.totalValue ?? 0}
            />
          </Grid>
          <Hidden smDown>
            <Grid item md={2}>
              <Typography variant="body1" className={`${classes.columnText}`}>
                {declaredValue ? `$${declaredValue}` : 'N/A'}
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography variant="body1" className={`${classes.columnText}`}>
                {distance ? `${distance} mi` : 'N/A'}
              </Typography>
            </Grid>
            <Grid item md={3}>
              <LastEvent event={lastEvent} />
            </Grid>
            <Grid item md={3}>
              <Driver driver={driver} />
              <DynamicEta
                gigId={gig.id}
                dynamicEtaTimestamp={dynamicEtaTimestamp}
                dynamicEtaDistanceRemaining={dynamicEtaDistanceRemaining}
                deliveryLocation={deliveryLocation}
                layout={'stacked'}
              />
            </Grid>
            <Grid item md={2}>
              <Typography variant="body1" className={`${classes.columnText}`}>
                <DriverExpectation
                  driverExpectation={driverExpectation}
                  deliveryLocation={deliveryLocation}
                />
              </Typography>
            </Grid>
          </Hidden>
          <Grid item md={2} xs={4}>
            <Deadline
              deliveryDeadline={deliveryDeadline}
              deliveryLocation={deliveryLocation}
            />
          </Grid>
        </Grid>
      </AccordionSummary>
      {isGigExpanded && (
        <Fade in>
          <AccordionDetails>
            <ErrorBoundary>
              <GigDetails
                gigId={gig.id}
                dynamicEtaTimestamp={dynamicEtaTimestamp}
                dynamicEtaDistanceRemaining={dynamicEtaDistanceRemaining}
                boardId={boardId}
                showFirstAndLastName={showFirstAndLastName}
                textlineGroupUuid={textlineGroupUuid}
              />
            </ErrorBoundary>
          </AccordionDetails>
        </Fade>
      )}
    </Accordion>
  );
};

import React from 'react'
import { isLoggedIn } from './auth'
import SignIn from "./components/SignIn"

export const PrivateRoute = (props,...use) => {
    const Component = props.component;
    return isLoggedIn() ? (
        <Component {...use} />
    ) : (
        <SignIn from={window.location}/>
    )
}

import React, { Component, Fragment } from 'react';
import { Typography, Link, useTheme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Countdown from 'react-countdown-now';
import Moment from 'react-moment';
import 'moment-timezone';
import { LightToolTip } from './CustomToolTip';

const useStyles = makeStyles((theme) =>
  createStyles({
    rowText: {
      color: "#7A7A7A",
      fontSize: 15,
      fontWeight: 500 
    },
    linkText: {
      color: "#7A7A7A",
      fontSize: 17,
      fontWeight: 500 
    },
    toolTipTitle: {
      fontSize: 15,
      letterSpacing: 0.4,
      color: "#7A7A7A"
    }
  })
);

const countdownTimer = ({ total, hours, minutes, completed }) => {
  if (completed) return;
  return <span>{hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`}</span>;
};


const TrackingPointsLink = ({gigId}) => {
    return (
      <Link 
        style={{ color: "#008383", textTransform: 'uppercase', letterSpacing: 1.25 }}
        target="_blank"
        href={`https://admin.roadie.com/gigs/${ gigId }/tracking_points`}
      >
        View Map
      </Link>
    )
  }

export const DynamicEta = ({
  gigId,
  dynamicEtaTimestamp,
  dynamicEtaDistanceRemaining,
  deliveryLocation,
  layout
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  let today = new Date()
  let dynamicEtaTimestampHTML;
  let countdownDistance;
  let distanceRemaining = null;
  let dynamicEtaCountdown = null;
  let format = "h:mma z"
  let localTime

  if (dynamicEtaTimestamp) {
    let dynamicEtaFromNow;
    let dynamicEta = new Date(dynamicEtaTimestamp)
    localTime = <Moment format={ format } tz={ deliveryLocation.timezone }>{ dynamicEta }</Moment>
    if (today < dynamicEta) {
      dynamicEtaCountdown = <Countdown date={ dynamicEta } renderer={ countdownTimer }/> 
    } else {
      dynamicEtaFromNow = <Moment fromNow>{ dynamicEta }</Moment>
    }

    let title = (
      <Fragment>
        <Typography variant="body2" className={classes.toolTipTitle}>
          ({localTime} • {dynamicEtaCountdown || dynamicEtaFromNow })
        </Typography>
      </Fragment>
    )
    
    dynamicEtaTimestampHTML = (
      <LightToolTip title={title} arrow>
        <span>
        <Moment format="h:mma z" tz="America/New_York" style={{ margin: "0 2px" }}>
            { dynamicEta }
          </Moment>
          arrival
        </span>
      </LightToolTip>
    )
  }
  if (dynamicEtaDistanceRemaining) {
    let distance = dynamicEtaDistanceRemaining < 1
      ? "< 1"
      : dynamicEtaDistanceRemaining
    distanceRemaining = <span>{ distance }mi away</span>
  }

  let separator = dynamicEtaCountdown !== null && distanceRemaining !== null
    ? " • "
    : ""
  
  if (dynamicEtaCountdown !== null || distanceRemaining !== null) {
    countdownDistance = (
      <span style={{ marginLeft: "2px" }}>
        ({ dynamicEtaCountdown }{ separator }{ distanceRemaining })
      </span>
    )
  }

  if (layout === "stacked") {
    return (
      <span>
        <Typography variant="body2" className={classes.rowText}>
          { dynamicEtaTimestampHTML }
        </Typography>
        <Typography variant="body2" className={classes.rowText}>
          { countdownDistance }
        </Typography>
        <Typography variant="body2" className={classes.linkText}>
          <TrackingPointsLink gigId={gigId}/>
        </Typography>
      </span>
    )
  } else if (layout === "horizontal") {
    return (
      <span>
        <Typography variant="body2" className={classes.rowText}>
          { dynamicEtaTimestampHTML } { countdownDistance }
          <TrackingPointsLink gigId={gigId} style={{ marginLeft: "2px" }}/>
        </Typography>
      </span>
    )
  } else {
    return (
      <span>
        <Typography variant="body2" className={classes.rowText}>
          { dynamicEtaTimestampHTML } { countdownDistance }
        </Typography>
        <Typography variant="body2" className={classes.linkText}>
          <TrackingPointsLink gigId={gigId}/>
        </Typography>
      </span>
    )
  }
}

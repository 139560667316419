import { gql } from '@apollo/client';

export const GetGigsByFilter = gql`
  query GetGigsByFilter($filter: String!) {
    id
    myBoards {
      id
      name
      showFirstAndLastName
      gigs(filter: $filter) {
        id
      }
    }
  }
`;

import React, { Component } from 'react';
import { Query } from '@apollo/client/react/components';

import Loader from './Loader';
import GroupForm from './GroupForm';

import { GetGroupForEdit } from '../queries/getGroupForEdit';

class EditGroup extends Component {
  state = {
    groupId: null,
    groupMutation: null
  };

  componentDidMount() {
    let { groupId, groupMutation } = this.props;
    this.setState({ groupId, groupMutation });
  }

  render() {
    const { closeHandler } = this.props;
    const { groupId, groupMutation } = this.state;
    return (
      <Query query={GetGroupForEdit} variables={{ id: groupId }}>
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) return <div>{error}</div>;

          if (data) {
            return (
              <GroupForm
                groupMutation={groupMutation}
                group={data.group}
                groupId={groupId}
                closeHandler={closeHandler}
              />
            );
          }
        }}
      </Query>
    );
  }
}

export default EditGroup;

import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Snackbar,
  IconButton
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Mutation } from '@apollo/client/react/components';
import { green } from '@material-ui/core/colors';
import TextsmsIcon from '@material-ui/icons/Textsms';
import CloseIcon from '@material-ui/icons/Close';

import { SendMessageToDriver } from '../mutations/SendMessageToDriver';

const styles = (theme) => ({
  root: {
    overflow: 'visible'
  },
  divider: {
    height: theme.spacing(2)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  icon: {
    marginRight: theme.spacing(1)
  }
});

class MessageDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      messageTemplate: {},
      snackbarOpen: false,
      snackbarMessage: '',
      snackbarStatus: 'success',
      textlineGroupUuid: null,
    };
  }

  _handleCloseDialog = () => {
    this.setState({ open: false });
    this.props.formCloseHandler();
  };

  _handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  _onComplete = async (data) => {
    if (data.sendMessageToDriver.ok) {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: 'Text message sent!',
        snackbarStatus: 'success',
        open: false
      });
    } else {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: 'Error sending text message',
        snackbarStatus: 'error',
        open: false
      });
    }
  };

  componentDidMount() {
    let { gigId, messageTemplate, open, textlineGroupUuid } = this.props;
    this.setState({ gigId, messageTemplate, open, textlineGroupUuid });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let { gigId, messageTemplate, open, textlineGroupUuid } = newProps;
    this.setState({ gigId, messageTemplate, open, textlineGroupUuid });
  }

  render() {
    const { classes } = this.props;
    const {
      gigId,
      messageTemplate,
      open,
      snackbarOpen,
      snackbarMessage,
      snackbarStatus
    } = this.state;
    return (
      <span>
        <Dialog
          open={open}
          onClose={this._handleCloseDialog}
          TransitionProps={{ onExit: this._handleCloseDialog }}
          maxWidth="md"
          classes={{ paperScrollPaper: classes.root }}
        >
          <DialogTitle>Confirm Message</DialogTitle>

          <DialogContent>
            <Typography variant="subtitle2">{messageTemplate.title}</Typography>
            <Typography variant="body1">{messageTemplate.preview}</Typography>
          </DialogContent>

          <DialogActions>
            <Button onClick={this._handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Mutation
              mutation={SendMessageToDriver}
              onCompleted={(data) => this._onComplete(data)}
            >
              {(sendMessage, { loading, error, data }) => (
                <Button
                  disabled={loading}
                  onClick={() =>
                    sendMessage({
                      variables: {
                        gigId: gigId,
                        templateId: messageTemplate.id,
                        textlineGroupUuid: this.state.textlineGroupUuid
                      }
                    })
                  }
                  color="primary"
                >
                  Send Message
                </Button>
              )}
            </Mutation>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={this._handleSnackbarClose}
          message={
            <span className={classes.message}>
              <TextsmsIcon className={classes.icon} /> {snackbarMessage}
            </span>
          }
          ContentProps={{
            classes: {
              root: classes[snackbarStatus]
            }
          }}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this._handleSnackbarClose}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          ]}
        />
      </span>
    );
  }
}
export default withStyles(styles)(MessageDialog);

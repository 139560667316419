import { gql } from '@apollo/client';

export const GetUserForEdit = gql`
  query GetUserForEdit($id: ID!) {
    id
    user(id: $id) {
      id
      email
      name
      groups {
        id
        name
        roles {
          id
          name
        }
        boards {
          id
          name
        }
      }
      roles {
        id
        name
      }
      inheritedRoles {
        id
        name
      }
      boards {
        id
        name
      }
      inheritedBoards {
        id
        name
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const SearchBoards = gql`
  query SearchBoards($search: String) {
    id
    allBoards(search: $search) {
      id
      name
      updatedAt
      isAssignedToMe
      creator {
        id
        name
      }
    }
  }
`;

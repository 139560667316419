import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  Card,
  CardContent,
  CardActionArea,
  CardHeader,
  Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  RED_COLOR,
  ORANGE_COLOR,
  YELLOW_COLOR,
  GREEN_COLOR
} from '../constants';

import '../animate.css';
import { Animated } from 'react-animated-css';

const styles = (theme) => ({
  root: {
    backgroundColor: '#a5d6a7'
  },
  red: {
    backgroundColor: RED_COLOR
  },
  orange: {
    backgroundColor: ORANGE_COLOR
  },
  yellow: {
    backgroundColor: YELLOW_COLOR
  },
  green: {
    backgroundColor: GREEN_COLOR
  },
  boardTitle: {
    paddingBottom: 0
  }
});

class BoardOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      board: { stats: {} },
      key: Math.random(),
      animationInClass: 'fadeIn'
    };
  }

  componentDidMount() {
    const { match, board } = this.props;
    if (match && match.params && match.params.board) {
      this.setState({ board: match.params.board });
    } else {
      this.setState({ board: board });
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.board !== this.state.board) {
      let animationClass = null;
      if (
        newProps.board.boardColor === 'red' &&
        this.state.board.boardColor !== 'red'
      ) {
        animationClass = 'flash';
      } else {
        let newCounts = newProps.board.counts.map((bucket) => {
          return bucket.gigCount;
        });
        let oldCounts = this.state.board.counts.map((bucket) => {
          return bucket.gigCount;
        });
        if (JSON.stringify(newCounts) !== JSON.stringify(oldCounts)) {
          animationClass = 'fadeIn';
        }
      }
      this.setState({
        board: newProps.board,
        animationInClass: animationClass,
        key: Math.random()
      });
    }
  }

  _minimalPriorityGigs = (buckets) => {
    return buckets
      .filter((bucket) => bucket.priority === 'minimal')
      .reduce((acc, { gigCount }) => acc + gigCount, 0);
  };

  render() {
    const { classes } = this.props;
    const { board, key, animationInClass } = this.state;
    if (board.counts) {
      return (
        <Animated animationIn={animationInClass} key={key}>
          <Card className={classes[board.boardColor]}>
            <CardActionArea component={Link} to={`/boards/${board.id}`}>
              <CardHeader
                className={classes.boardTitle}
                title={
                  <span>
                    <Typography variant="h5" noWrap>
                      {board.name}
                    </Typography>
                    <Typography variant="body2">
                      {this._minimalPriorityGigs(board.counts)} Don't Worry
                    </Typography>
                  </span>
                }
              />
              <CardContent>
                <Grid container spacing={1} justifyContent="space-evenly">
                  {board.counts.map((bucket) => {
                    if (bucket.priority !== 'minimal') {
                      return (
                        <Grid item xs={6} lg={3} key={bucket.id}>
                          <Typography
                            variant={
                              ['critical', 'high'].includes(bucket.priority)
                                ? 'h4'
                                : 'h6'
                            }
                            align="center"
                          >
                            {bucket.gigCount}
                          </Typography>
                          <Typography paragraph align="center">
                            {bucket.title}
                          </Typography>
                        </Grid>
                      );
                    } else {
                      return <span></span>;
                    }
                  })}
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        </Animated>
      );
    } else {
      return <div></div>;
    }
  }
}

export default withStyles(styles)(BoardOverview);

import React, { Component } from 'react'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import GroupDialog from './GroupDialog';

const styles = theme => ({})


class EditGroupButton extends Component {
  state = {
    group: {},
    open: false
  }

  _handleEdit = () => {
    this.setState({ open: true });
  }

  _handleFormClose = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    let { group } = this.props
    this.setState({ group })
  }

  render() {
    const { group, open } = this.state
    return(
      <span>
        <Button onClick={this._handleEdit}>Edit</Button>
        <GroupDialog group={ group } open={ open } formCloseHandler={ this._handleFormClose }/>
      </span>
    )
  }
}
export default withStyles(styles)(EditGroupButton)

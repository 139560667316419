import { gql } from '@apollo/client';

const BID_FRAGMENT = gql`
  fragment BidFragment on Bid {
    profile {
      id
      name
    }
    vehicle {
      id
      friendlyName
    }
    hasRequiredCerts
    certMap
    status
    message
    cancellationComment
    cancelledAfterAcceptedAt
    cancellationCategory
    rejectionReason
    acceptedAt
    timestamp
  }
`;
export const BidFragments = [BID_FRAGMENT];

import { gql } from '@apollo/client';

export const SearchRoles = gql`
  query SearchRoles($search: String!) {
    id
    allRoles(search: $search) {
      id
      name
    }
  }
`;

import React from 'react';
import SendMessageButton from './SendMessageButton';
import { TextlineHistory } from './TextlineHistory';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import Loader from './Loader';

const styles = (theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  }
});

const useStyles = makeStyles((theme) => createStyles(styles(theme)));

export const MessagesListing = ({ gig, data, loading, error, textlineGroupUuid }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  if (loading) return <Loader />;
  if (error) return <div>{error.message}</div>;
  if (data) {
    return (
      <div className={classes.root}>
        {data.messageTemplates.map((template) => (
          <SendMessageButton
            key={template.id}
            messageTemplate={template}
            gigId={gig.id}
            textlineGroupUuid={ textlineGroupUuid }
          />
        ))}
        <TextlineHistory gig={gig} />
      </div>
    );
  }
};

import React from 'react';
import {
  Chip,
  Button,
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import { useTheme, makeStyles, createStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import DirectionsIcon from '@material-ui/icons/Directions';
import TimestampTableCell from './TimestampTableCell';
import TimerIcon from '@material-ui/icons/Timer';
import titleize from 'titleize';
import { getGigEventDisplayName } from '../Helper';

const styles = (theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  delivered: {
    backgroundColor: '#f7f7f7',
    '& > td': {
      color: '#7a7a7a'
    }
  },
  delivery_attempted: {
    backgroundColor: '#f7f7f7',
    '& > td': {
      color: '#7a7a7a'
    }
  },
  cancelled: {
    backgroundColor: red[50]
  },
  icons: {
    verticalAlign: 'middle'
  }
});

const useStyles = makeStyles((theme) => createStyles(styles(theme)));

export const RouteListing = ({ gigId, route }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const {
    estimatedDistance = null,
    estimatedDriveTime = null,
    consolidationGigs = []
  } = route;
  return (
    <div className={classes.root}>
      <Grid container spacing={5} alignItems="center">
        <Grid item>
          <Typography variant="body2">Original Batch Estimates:</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" title="Estimated Distace">
            {estimatedDistance && (
              <>
                <DirectionsIcon className={classes.icons} />{' '}
                {`${estimatedDistance}mi`}
              </>
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" title="Estimated Drive Time">
            {estimatedDriveTime && (
              <>
                <TimerIcon className={classes.icons} />{' '}
                {`${estimatedDriveTime} min`}
              </>
            )}
          </Typography>
        </Grid>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Order</TableCell>
            <TableCell align="left">Gig</TableCell>
            <TableCell align="left">State</TableCell>
            <TableCell align="right">Last Event</TableCell>
            <TableCell align="right">Last Event Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {consolidationGigs &&
            consolidationGigs?.map((routeGig, index) => {
              const {
                id: routeGigId,
                state = null,
                lastEvent = null,
                title = null
              } = routeGig;
              return (
                <TableRow key={routeGigId} className={state && classes[state]}>
                  <TableCell align="center">
                    {routeGigId === gigId ? (
                      <Chip label={index + 1} color="primary" />
                    ) : (
                      index + 1
                    )}
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      href={`https://admin.roadie.com/gigs/${routeGigId}`}
                      color="primary"
                      target="_blank"
                    >
                      {!!title && title}
                    </Button>
                  </TableCell>
                  <TableCell align="left">
                    {state && titleize(state.replace('_', ' '))}
                  </TableCell>

                  <TableCell align="right">
                    {lastEvent?.eventType &&
                      getGigEventDisplayName(lastEvent?.eventType)}
                  </TableCell>
                  <TableCell align="right">
                    {lastEvent?.timestamp && (
                      <TimestampTableCell timestamp={lastEvent?.timestamp} />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </div>
  );
};

import React, { Component } from 'react'
import GroupForm from './GroupForm'

class CreateGroup extends Component {
  render() {
    const { closeHandler, groupMutation } = this.props
    return(
      <GroupForm
        newGroup={ true }
        group={ {} }
        groupMutation={ groupMutation }
        closeHandler={ closeHandler }
      />
    )
  }
}

export default CreateGroup

import { Tooltip } from '@material-ui/core';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const LightToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#7A7A7A',
    boxShadow: theme.shadows[1],
    fontSize: 15,
    maxWidth: 220,
  },
}));

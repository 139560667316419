import gql from 'graphql-tag';

export default gql`
  query getBoardSettings {
    id
    boardSettings {
      id
      textlineChannels
    }
  }
`;
import { gql } from '@apollo/client';

export const AssignMutation = gql`
  mutation AssignMutation($id: ID!) {
    assignBoard(id: $id) {
      ok
      board {
        id
        isAssignedToMe
      }
    }
  }
`;

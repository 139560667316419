import React, { Component } from 'react'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import MessageDialog from './MessageDialog';

const styles = theme => ({
  root: {
    display: 'inline-block',
  }
})

class SendMessageButton extends Component {
  state = {
    messageTemplate: {},
    gigId: null,
    open: false,
    textlineGroupUuid: null,
  }

  _handleOpen = () => {
    this.setState({ open: true });
  }

  _handleFormClose = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    let { messageTemplate, gigId, textlineGroupUuid } = this.props
    this.setState({ messageTemplate, gigId, textlineGroupUuid })
  }

  render() {
    const { gigId, messageTemplate, open, textlineGroupUuid } = this.state
    const { classes } = this.props
    if(messageTemplate) {
      return(
        <span className={ classes.root }>
          <Button onClick={ this._handleOpen } variant="contained" color="primary">{ messageTemplate.title }</Button>
          <MessageDialog messageTemplate={ messageTemplate } gigId={ gigId } open={ open } formCloseHandler={ this._handleFormClose } textlineGroupUuid={ textlineGroupUuid }/>
        </span>
      )
    } else {
      return(<span></span>)
    }
  }
}
export default withStyles(styles)(SendMessageButton)

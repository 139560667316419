import { gql } from '@apollo/client';

export const TextlineConversation = gql`
  query TextlineConversation($gigId: ID!) {
    id
    gig(id: $gigId) {
      id
      textlineConversation {
        id
        link
        posts {
          id
          body
          timestamp
          author
          whisper
          attachments {
            id
            url
          }
        }
      }
    }
  }
`;

import React, { useCallback, useState, useRef } from 'react';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  Fade,
  Popover,
  useTheme
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { Bucket } from './Bucket';
import { BucketCount } from './BucketCount';
import { useBoardContext } from '../hooks/useBoardContext';

const styles = (theme) => ({
  red: {
    backgroundColor: '#F3AEAE'
  },
  orange: {
    backgroundColor: '#FFD69A'
  },
  yellow: {
    backgroundColor: '#FEF7B1'
  },
  green: {
    backgroundColor: '#B7DEB9'
  },
  gray: {
    backgroundColor: '#DCDCDC'
  },
  column: {
    flexBasis: '25%'
  },
  gigRow: {
    flex: '0 0 100%'
  },
  panelDetails: {
    padding: 0
  },
  section: {
    marginBottom: 15,
    boxShadow: 'unset'
  },
  popover: {
    padding: theme.spacing(1),
    pointerEvents: 'none'
  },
  infoIcon: {
    width: 20,
    paddingLeft: 8
  },
  titleWithIcon: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    alignItems: 'center',
    fontSize: 22
  },
  accordianSummary: {
    maxHeight: 60,
    minHeight: 60,
    paddingLeft: 32,
    paddingRight: 34
  }
});

const filterColorMap = {
  find_driver: 'red',
  contact_driver: 'orange',
  monitor: 'yellow',
  dont_worry: 'green',

  critical: 'red',
  high: 'orange',
  medium: 'yellow',
  low: 'green',
  minimal: 'gray'
};

const useStyles = makeStyles((theme) => createStyles(styles(theme)));

const Title = ({ classes, description, title = '' }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const popoverRef = useRef(null);

  if (description) {
    return (
      <span
        ref={popoverRef}
        className={classes.titleWithIcon}
        aria-haspopup="true"
        onMouseEnter={() => setPopoverOpen(true)}
        onMouseLeave={() => setPopoverOpen(false)}
      >
        {title}
        <InfoOutlinedIcon className={classes.infoIcon} />
        <Popover
          className={classes.popover}
          open={popoverOpen}
          anchorEl={popoverRef.current}
          anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
          transformOrigin={{ vertical: 'center', horizontal: 'left' }}
          onClose={() => setPopoverOpen(false)}
          disableRestoreFocus
        >
          <Typography variant="caption" className={classes.popover}>
            {description}
          </Typography>
        </Popover>
      </span>
    );
  } else {
    return <span>{title}</span>;
  }
};

export const BoardWithBuckets = ({
  board,
  priority,
  rule,
  isStandardBoard = true,
  appliedFilters
}) => {
  const bucketAccsr = isStandardBoard ? 'buckets' : 'bucketsByPriority';
  const {
    bucketsExpandedState,
    setBucketExpandedState,
    setInitialBucketsState
  } = useBoardContext();

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      {board[bucketAccsr]?.map((bucket) => {
        const isExpanded = bucketsExpandedState[bucket.id] || false;

        const gigCount = appliedFilters
          ? bucket?.filteredGigCount
          : bucket?.gigCount;

        const headerColorClass = bucket?.color
          ? classes[bucket?.color]
          : classes[filterColorMap[priority]];
        return (
          <div key={bucket.id}>
            <Fade in>
              <div styles={classes.section}>
                <Accordion
                  defaultExpanded={isExpanded}
                  square
                  elevation={1}
                  expanded={isExpanded}
                  className={classes.section}
                  onChange={(_, isExpanded) =>
                    setBucketExpandedState(bucket.id, isExpanded)
                  }
                >
                  <AccordionSummary
                    className={`${headerColorClass} ${classes.accordianSummary}`}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Grid container className={`${classes.title}`}>
                      <Grid item xs={9}>
                        <Typography variant="h6">
                          <Title
                            classes={classes}
                            description={bucket?.description}
                            title={`${board?.name} - ${bucket?.title}`}
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={3} align="right">
                        <BucketCount
                          bucketId={bucket.id}
                          totalGigCount={gigCount}
                        />
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  {isExpanded && (
                    <Bucket
                      key={`${bucket.id}`}
                      title={`${board.name} - ${bucket.title}`}
                      priority={priority}
                      boardId={board.id}
                      bucketId={bucket.id}
                      gigCount={gigCount}
                      rule={rule}
                      showFirstAndLastName={board.showFirstAndLastName}
                      isStandardBoard={isStandardBoard}
                      headerColor={bucket?.color}
                      appliedFilters={appliedFilters}
                    />
                  )}
                </Accordion>
              </div>
            </Fade>
          </div>
        );
      })}
    </>
  );
};

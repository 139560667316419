import { AUTH_TOKEN, CURRENT_USER } from './constants'

export const currentUser = () => JSON.parse(localStorage.getItem(CURRENT_USER));
export const isLoggedIn = () => !!currentUser();

export const hasRoles = (roles) => {
  if(currentUser()) {
    try {
      return roles.some(role => currentUser().allRoles.map(r => r.name).includes(role));
    }
    catch {
      signOut()
    }
  }
  return false
}

export const isExternal = () => {
  return hasRoles(['dashboard_external'])
}

export const signOut = () => {
  localStorage.removeItem(AUTH_TOKEN)
  localStorage.removeItem(CURRENT_USER)
}

export const signIn = (data) => {
  const { token, user } = data.signIn
  localStorage.setItem(AUTH_TOKEN, token)
  localStorage.setItem(CURRENT_USER, JSON.stringify(user))
}


import { gql } from '@apollo/client';

export const SignInMutation = gql`
  mutation SignInMutation($email: String!, $password: String!) {
    signIn(auth: { email: $email, password: $password }) {
      token
      user {
        id
        name
        email
        allRoles {
          id
          name
        }
      }
    }
  }
`;

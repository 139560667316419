import React from 'react';
import { useQuery } from '@apollo/client';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  useTheme
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Loader from './Loader';
import RouteMap from './RouteMap';
import { DynamicEta } from './DynamicEta'

import { GetMapForGig } from '../queries/getGig';
import { POLLING_INTERVALS } from '../constants';

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      boxShadow: "none",
      border: "1px solid #EAEAEA"
    },
    locations: {
      verticalAlign: 'middle',
    },
    locationName: {
      verticalAlign: 'middle',
      fontSize: 17 
    },
    locationStoreNumber: {
      verticalAlign: 'middle',
      fontSize: 15
    },
    arrowForward: {
      alignContent: "center"
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  })
);

export const MapCard = ({ 
  gigId,
  dynamicEtaTimestamp,
  dynamicEtaDistanceRemaining 
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { data, loading, error } = useQuery(GetMapForGig, {
    variables: { gigId },
    pollInterval: POLLING_INTERVALS.GIG
  });

  if (loading) return <Loader />;
  if (error) return <div>{error.message}</div>;
  if (data && data?.gig) {
    const { pickupLocation, deliveryLocation } = data.gig;
    return (
      <Card className={classes.card}>
        <CardContent>
          <Grid
            container
            alignItems="top"
            justifyContent="space-between"
            style={{ marginBottom: 16}}
          >
            <Grid item>
              <Typography variant="body1" className={classes.locationName}>
                {pickupLocation?.city}, {pickupLocation?.state}
              </Typography>
              <Typography variant="body2" className={classes.locationStoreNumber}>
                {pickupLocation?.storeNumber
                  ? `Store ${pickupLocation?.storeNumber}`
                  : ''}
              </Typography>
            </Grid>
            <Grid item className={classes.arrowForward}>
              <Typography variant="body1" className={classes.locations}>
                <ArrowForwardIcon fontSize="small"/>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" className={classes.locationName}>
                {deliveryLocation?.city}, {deliveryLocation?.state}
              </Typography>
              <Typography variant="body2" className={classes.locationStoreNumber}>
                {deliveryLocation?.storeNumber
                  ? `Store ${deliveryLocation?.storeNumber}`
                  : ''}
              </Typography>
            </Grid>
          </Grid>
          <DynamicEta 
            gigId={gigId}
            dynamicEtaTimestamp={dynamicEtaTimestamp}
            dynamicEtaDistanceRemaining={dynamicEtaDistanceRemaining}
            deliveryLocation={deliveryLocation}
            layout={"horizontal"}
          />
          <Grid
            container
            alignItems="center"
            spacing={2}
            style={{ marginTop: 20 }}
          >
            <Grid item xs={12}>
              <RouteMap gig={data?.gig} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
};

import React, { Component } from 'react'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import UserDialog from './UserDialog';

const styles = theme => ({})


class EditUserButton extends Component {
  state = {
    user: {},
    open: false
  }

  _handleEdit = () => {
    this.setState({ open: true });
  }

  _handleFormClose = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    let { user } = this.props
    this.setState({ user })
  }

  render() {
    const { user, open } = this.state
    return(
      <span>
        <Button onClick={this._handleEdit}>Edit</Button>
        <UserDialog user={ user } open={ open } formCloseHandler={ this._handleFormClose }/>
      </span>
    )
  }
}
export default withStyles(styles)(EditUserButton)

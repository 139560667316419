import React from 'react';
import { Grid, useTheme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { MapCard } from './MapCard';
import { GigExtendedDetailsTabs } from './GigExtendedDetailsTabs';

import { ErrorBoundary } from '@sentry/react';

const useStyles = makeStyles((theme) =>
  createStyles({
    detailsGrid: {
      minHeight: "710px"
    },
    extendedDetails: {
      height: "100%"
    }
  })
);

export const GigDetails = ({ 
  gigId,
  showFirstAndLastName,
  textlineGroupUuid,
  dynamicEtaTimestamp,
  dynamicEtaDistanceRemaining
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Grid container>
      <Grid
        container
        direction="row"
        spacing={2}
        justifyContent="space-between"
      >
        <Grid
          container
          direction="row"
          spacing={2}
          justifyContent="space-between"
          className={classes.detailsGrid}
        >
          <Grid item xs={12} md={5}>
            <ErrorBoundary>
              <MapCard
                gigId={gigId}
                dynamicEtaTimestamp={dynamicEtaTimestamp}
                dynamicEtaDistanceRemaining={dynamicEtaDistanceRemaining}
              />
            </ErrorBoundary>
          </Grid>

          <Grid item xs={12} md={7} className={classes.extendedDetails}>
            <GigExtendedDetailsTabs
              gigId={gigId}
              showFirstAndLastName={showFirstAndLastName}
              textlineGroupUuid={ textlineGroupUuid }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

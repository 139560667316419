import React from 'react';
import Select from 'react-select';
import { Typography } from '@material-ui/core';

export const SelectListContent = ({
  options,
  value,
  title,
  isMulti,
  maxWidth,
  minWidth,
  onChange
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: 5,
        marginRight: 20
      }}
    >
      <Typography style={{ marginRight: 15 }}>{title} :</Typography>
      <Select
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            width: 'auto',
            maxWidth: maxWidth,
            minWidth: minWidth,
            fontFamily: 'canada-type-gibson, sans-serif',
            fontSize: 'large'
          }),
          option: (styles) => ({
            ...styles,
            fontFamily: 'canada-type-gibson, sans-serif',
            fontSize: 'large'
          })
        }}
        defaultValue={value}
        onChange={onChange}
        options={options}
        isMulti={isMulti}
      />
    </div>
  );
};

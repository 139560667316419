import React, { Component } from 'react'
import BoardForm from './BoardForm'

class CreateBoard extends Component {
  render() {
    const { closeHandler, boardMutation } = this.props
    return(
      <BoardForm
        newBoard={ true }
        board={ {} }
        boardMutation={ boardMutation }
        closeHandler={ closeHandler }
      />
    )
  }
}

export default CreateBoard

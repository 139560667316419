import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Paper,
  Button,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Grid
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withApollo } from '@apollo/client/react/hoc';
import AddIcon from '@material-ui/icons/Add';

import TablePaginationActions from './TablePaginationActions';
import ToggleAssignButton from './ToggleAssignButton';
import EditBoardButton from './EditBoardButton';
import DeleteBoardButton from './DeleteBoardButton';
import BoardDialog from './BoardDialog';

import { SearchBoards } from '../queries/searchBoards';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  }
});

class BoardListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      createFormOpen: false,
      editFormOpen: false,
      search: '',
      boards: []
    };
  }

  _handleChangePage = (event, page) => {
    this.setState({ page });
  };

  _handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  _handleEdit = () => {
    this.setState({ editFormOpen: true });
  };

  _handleFormClose = () => {
    this.setState({ editFormOpen: false });
  };

  _refresh = () => {
    window.location = '/boards';
  };

  _executeSearch = async () => {
    let { search } = this.state;
    let result = await this.props.client.query({
      query: SearchBoards,
      variables: { search }
    });
    let boards = result.data.allBoards;
    this.setState({ boards });
  };

  _searchFilter = async (search) => {
    await this.setState({ search });
    this._executeSearch();
  };

  _handleCreateBoard = () => {
    this.setState({ createFormOpen: true });
  };

  _handleCreateFormClose = () => {
    this.setState({ createFormOpen: false });
  };

  componentDidMount() {
    this._executeSearch();
  }

  render() {
    const { classes } = this.props;
    const { rowsPerPage, page, boards } = this.state;

    return (
      <Paper square className={classes.root}>
        <Grid justifyContent="space-between" container>
          <Grid item>
            <Typography variant="h5">All Boards</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" align="right">
              <Button onClick={this._handleCreateBoard}>
                <AddIcon className={classes.leftIcon} />
                Create New Board
              </Button>
            </Typography>
            <TextField
              label="Search"
              margin="normal"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this._searchFilter(e.target.value);
                }
              }}
            />
          </Grid>
        </Grid>
        <Table className={classes.table} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              <TableCell key="name">Name</TableCell>
              <TableCell key="creator">Creator</TableCell>
              <TableCell key="updated_at">Last Updated</TableCell>
              <TableCell key="actions" align="right">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {boards
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((board) => {
                let creator = '[deleted user]';
                if (board.creator) {
                  creator = board.creator.name;
                }
                return (
                  <TableRow hover tabIndex={-1} key={board.id}>
                    <TableCell>
                      <Link to={`/boards/${board.id}`}>{board.name}</Link>
                    </TableCell>
                    <TableCell>{creator}</TableCell>
                    <TableCell>{board.updatedAt}</TableCell>
                    <TableCell align="right">
                      <ToggleAssignButton board={board} />
                      <EditBoardButton board={board} />
                      <DeleteBoardButton
                        boardId={board.id}
                        refresh={this._refresh}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                colSpan={5}
                count={boards.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{ native: true }}
                onChangePage={this._handleChangePage}
                onChangeRowsPerPage={this._handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
        <BoardDialog
          newBoard
          board={{}}
          open={this.state.createFormOpen}
          formCloseHandler={this._handleCreateFormClose}
          submitHandler={this._refresh}
        />
      </Paper>
    );
  }
}
export default withStyles(styles)(withApollo(BoardListing));

import { gql } from '@apollo/client';

const BOARD_FRAGMENT = gql`
  fragment BoardFragment on Board {
    name
    showFirstAndLastName
    boardColor
  }
`;

const BOARD_COUNTS_FRAGMENT = gql`
  fragment BoardCountsFragment on Board {
    id
    title
    priority
  }
`;

const BOARD_WITH_BUCKETS_FRAGMENT = gql`
  fragment BoardWithBucketsFragment on Board {
    id
    ...BoardFragment
    buckets {
      id
      ...BucketFragment
    }
  }
`;

const BOARD_WITH_PRIORITY_BUCKETS_FRAGMENT = gql`
  fragment BoardWithBucketsByPriorityFragment on Board {
    id
    ...BoardFragment
    bucketsByPriority(priority: $priority, title: $title) {
      id
      ...BucketFragment
    }
  }
`;

export const BoardFragments = [
  BOARD_FRAGMENT,
  BOARD_COUNTS_FRAGMENT,
  BOARD_WITH_BUCKETS_FRAGMENT,
  BOARD_WITH_PRIORITY_BUCKETS_FRAGMENT
];

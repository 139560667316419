import { gql } from '@apollo/client';

export const SearchProfiles = gql`
  query SearchProfiles($search: String!) {
    id
    allProfiles(search: $search) {
      id
      name
      firstAndLastName
    }
  }
`;

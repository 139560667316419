import React from 'react';
import { useMutation } from '@apollo/client';
import { ListItem, ListItemText } from '@material-ui/core';

import { SignOutMutation } from '../mutations/SignOut';

import { signOut } from '../auth';

export const SignOutButton = () => {
  const [sendSignOut, { client }] = useMutation(SignOutMutation, {
    onCompleted: () => {
      signOut();
      client.resetStore();
      window.location = '/';
    }
  });

  return (
    <ListItem button component="a" href="#" onClick={sendSignOut}>
      <ListItemText primary="Sign Out" />
    </ListItem>
  );
};

import React, { Component } from 'react';
import { Dialog } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Mutation } from '@apollo/client/react/components';
import CreateBoard from './CreateBoard';
import EditBoard from './EditBoard';
import { CreateBoardMutation } from '../mutations/CreateBoard';
import { UpdateBoardMutation } from '../mutations/UpdateBoard';

const styles = (theme) => ({
  divider: {
    height: theme.spacing(2)
  }
});

class BoardDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      board: {}
    };
  }

  _handleCloseDialog = () => {
    this.setState({ open: false });
    this.props.formCloseHandler();
  };

  _mutation = () => {
    if (this.props.newBoard) {
      return CreateBoardMutation;
    } else {
      return UpdateBoardMutation;
    }
  };

  _onComplete = async (data) => {
    if (this.props.submitHandler) {
      this.props.submitHandler();
    } else {
      window.location = '/';
    }
  };

  componentDidMount() {
    let { board, open } = this.props;
    this.setState({ board, open });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let { board, open } = newProps;
    this.setState({ board, open });
  }

  render() {
    const { board, open } = this.state;
    return (
      <Dialog
        open={open}
        onClose={this._handleCloseDialog}
        TransitionProps={{ onExit: this._handleCloseDialog }}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
        scroll="paper"
      >
        <Mutation
          mutation={this._mutation()}
          onCompleted={(data) => this._onComplete(data)}
        >
          {(boardMutation, { data, error }) => {
            if (error) return <p>Error.</p>;

            if (this.props.newBoard) {
              return (
                <CreateBoard
                  boardMutation={boardMutation}
                  closeHandler={this._handleCloseDialog}
                />
              );
            }
            return (
              <EditBoard
                boardMutation={boardMutation}
                boardId={board.id}
                closeHandler={this._handleCloseDialog}
              />
            );
          }}
        </Mutation>
      </Dialog>
    );
  }
}
export default withStyles(styles)(BoardDialog);

import React, { Component } from 'react'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import BoardDialog from './BoardDialog';

const styles = theme => ({})


class EditBoardButton extends Component {
  state = {
    board: {},
    open: false
  }

  _handleEdit = () => {
    this.setState({ open: true });
  }

  _handleFormClose = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    let { board } = this.props
    this.setState({ board })
  }

  render() {
    const { board, open } = this.state
    return(
      <span>
        <Button onClick={this._handleEdit}>Edit</Button>
        <BoardDialog board={ board } open={ open } formCloseHandler={ this._handleFormClose }/>
      </span>
    )
  }
}
export default withStyles(styles)(EditBoardButton)

import { gql } from '@apollo/client';

const ADMIN_GIG_NOTE_CONNECTION_FRAGMENT = gql`
  fragment AdminGigNoteConnectionFragment on AdminGigNoteConnection {
    nodes {
      id
      message
      timestamp
      adminUser {
        id
        name
      }
    }
  }
`;

const CONSOLIDATION_NOTE_CONNECTION_FRAGMENT = gql`
  fragment ConsolidationNoteConnectionFragment on ConsolidationNoteConnection {
    nodes {
      id
      message
      timestamp
      adminUser {
        id
        name
      }
    }
  }
`;

export const NoteFragments = [
  ADMIN_GIG_NOTE_CONNECTION_FRAGMENT,
  CONSOLIDATION_NOTE_CONNECTION_FRAGMENT
];

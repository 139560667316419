import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Mutation } from '@apollo/client/react/components';
import { UnassignMutation } from '../mutations/UnassignMutation';
import { AssignMutation } from '../mutations/AssignMutation';

const styles = (theme) => ({});

class ToggleAssignButton extends Component {
  state = {
    board: {}
  };

  _mutation = () => {
    if (this.state.board.isAssignedToMe) {
      return UnassignMutation;
    }
    return AssignMutation;
  };

  _handleToggle = (event, mutation) => {
    mutation({ variables: { id: this.state.board.id } });
  };

  _onComplete = (data) => {
    let { board } = data[Object.keys(data)[0]];
    this.setState({ board });
  };

  componentDidMount() {
    let { board } = this.props;
    this.setState({ board });
  }

  render() {
    const { board } = this.state;
    let buttonText = 'Add to Dashboard';
    if (board.isAssignedToMe) {
      buttonText = 'Remove from Dashboard';
    }
    return (
      <Mutation
        mutation={this._mutation()}
        onCompleted={(data) => this._onComplete(data)}
      >
        {(mutation, { data, error }) => {
          if (error) return <p>Error.</p>;
          return (
            <Button
              onClick={(e) => this._handleToggle(e, mutation)}
              color="primary"
            >
              {buttonText}
            </Button>
          );
        }}
      </Mutation>
    );
  }
}
export default withStyles(styles)(ToggleAssignButton);

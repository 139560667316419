import React from 'react';
import styled from '@emotion/styled';

import { GigValueFilter } from './GigValueFilter';
import { GigLastEventFilter } from './GigLastEventFilter';

const FiltersWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-direction: row-reverse;
`;

const ValueFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GigFilters = ({ appliedFilters, handleApplyFilters }) => {
  return (
    <FiltersWrapper>
      <ValueFilterWrapper>
        <GigValueFilter
          title={'Filter By Value'}
          appliedFilters={appliedFilters}
          onClickApply={handleApplyFilters}
        />
      </ValueFilterWrapper>
      <GigLastEventFilter
        title={'Last Event'}
        appliedFilters={appliedFilters}
        onClickApply={handleApplyFilters}
      />
    </FiltersWrapper>
  );
};

export default GigFilters;

import { gql } from '@apollo/client';

export const DeleteGroupMutation = gql`
  mutation DeleteGroupMutation($id: ID!) {
    deleteGroup(id: $id) {
      group {
        id
      }
    }
  }
`;

import { CUSTOM_EVENT_DISPLAY_NAMES } from './constants';

export const humanize = (str) => {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s]+/g, ' ')
    .replace(/^[a-z]/, function (m) {
      return m.toUpperCase();
    });
};

export const getBoardFromQueryResult = (data, boardId) => {
  return data.myBoards.find((board) => board.id === boardId);
};

// Buckets

export const getBucketFromQueryResult = (
  data,
  boardId,
  bucketId,
  bucketAccsr = 'buckets'
) => {
  const board = getBoardFromQueryResult(data, boardId);
  if (board && board[bucketAccsr]) {
    return board[bucketAccsr].find((bucket) => bucket.id === bucketId);
  }
  return null;
};

export const getNonEmptyBuckets = (buckets) => {
  return buckets.filter((bucket) => bucket?.gigs?.length > 0);
};

// Boards

export const getBoardIds = (data) => {
  return data.myBoards.map((board) => board.id);
};

export const getNonEmptyBoards = (boards, bucketAccsr = 'buckets') => {
  return boards.reduce((acc, board) => {
    const buckets = board[bucketAccsr];
    const nonEmptyBuckets = getNonEmptyBuckets(buckets);
    if (nonEmptyBuckets.length > 0) {
      const newBoard = { ...board, [bucketAccsr]: nonEmptyBuckets };
      return [...acc, newBoard];
    }
    return acc;
  }, []);
};

// Gig Events

export const getCustomEventDisplayNames = (data) => {
  CUSTOM_EVENT_DISPLAY_NAMES.forEach((element) => {
    let event = data.events.find((val) => val.label === element.original_name);
    if (event) {
      event.label = element.display_name;
    }
  });

  return data.events;
};

export const updateGigEventDisplayName = (gigs) => {
  const newGigs = gigs.map((gig) => {
    const customEvent = CUSTOM_EVENT_DISPLAY_NAMES.find(
      (val) => val.original_name === gig?.lastEvent?.eventType
    );
    if (customEvent) {
      const newGig = { ...gig };
      const lastEvent = {
        ...newGig.lastEvent,
        eventType: customEvent.display_name
      };
      return { ...newGig, lastEvent };
    }
    return gig;
  });
  return newGigs;
};

export const getGigEventDisplayName = (eventType) => {
  const customEvent = CUSTOM_EVENT_DISPLAY_NAMES.find(
    (val) => val.original_name === eventType
  );
  if (customEvent) {
    return customEvent.display_name;
  }
  return eventType;
};

export const formatGigEventsForDisplay = (events) => {
  return events.map((event) => {
    return getGigEventDisplayName(event);
  });
};

// Gig Offers/Bids

export const getCertsDisplayLabel = (hasRequiredCerts) => {
  if (hasRequiredCerts === true) {
    return 'Qualified';
  } else if (hasRequiredCerts === false) {
    return 'Missing';
  } else if (hasRequiredCerts === null || hasRequiredCerts === undefined) {
    return 'N/A';
  }
};

export const getShortTimezoneName = (date) => {
  const timezoneDateStr = date.toLocaleString('en-US', {
    timeZoneName: 'short'
  });

  const timezoneName = timezoneDateStr.split(' ').pop();
  if (!timezoneName) {
    throw new Error('Could not parse timezone name');
  }

  return timezoneName;
};
